import { Col, Row, Table, message } from 'antd';
import { DeleteOutlined } from "@ant-design/icons"
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../../components/atoms/ConfirmModal';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../../components/page-headers/page-headers';
import {
  useCreatePemusnahanMutation,
  useDeletePemusnahanMutation,
  useGetPemusnahanListMutation,
  useUpdatePemusnahanMutation,
} from '../../../../redux/service/pemindahanService';
import ModalForm from './components/ModalForm';
import { dataTableColumn } from './config';
import { useGetBarangQuery } from '../../../../redux/service/masterService';

const PageRoutes = [
  {
    path: 'index',
    breadcrumbName: 'Pemindahan',
  },
  {
    path: 'pemindahan/pemusnahan',
    breadcrumbName: 'Pemusnahan Barang',
  },
];
function PemusnahanBarangPage() {
  const dispatch = useDispatch();

  const { data: barangData, isLoading: barangLoading } = useGetBarangQuery();
  const [listHibah, pemusnahanBarangResult] = useGetPemusnahanListMutation();
  const [updatePemusnahanBarang, updatePemusnahanBarangResult] = useUpdatePemusnahanMutation();
  const [createPemusnahanBarang, createPemusnahanBarangResult] = useCreatePemusnahanMutation();
  const [deletePemusnahanBarang, deletePemusnahanBarangResult] = useDeletePemusnahanMutation();

  useEffect(() => {
    listHibah();
  }, []);

  const pemusnahanBarangList = pemusnahanBarangResult?.data?.data?.data || [];
  const handleCreatePemusnahanBarang = (e, callback) => {
    createPemusnahanBarang(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.data?.message);
      }
      callback();
      message.success(data?.message);
      return listHibah();
    });
  };

  const handleUpdatePemusnahanBarang = (e, callback) => {
    updatePemusnahanBarang({ formData: e, uuid: e.uuid }).then(({ error, data }) => {
      if (error) {
        return message.error(error?.message);
      }
      callback();
      message.success(data?.message);
      return listHibah();
    });
  };

  const handleDeletePemusnahanBarang = (record) => {
    deletePemusnahanBarang({ uuid: record.uuid }).then(({ data, error }) => {
      if (data) {
        message.success('Data Berhasil Dihapus');
        return listHibah();
      }

      message.error('Data Gagal Dihapus');
    });
  };

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Pemusnahan Barang"
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={15}>
          <Col xs={24}>
            <div>
              <Cards
                title="List Pemusnahan Barang"
                isbutton={
                  <ModalForm
                    onSubmit={(e, callback) => handleCreatePemusnahanBarang(e, callback)}
                    loading={createPemusnahanBarangResult.isLoading}
                    barang={barangData?.data?.data || []}
                  />
                }
              >
                <Table
                  pagination={false}
                  dataSource={pemusnahanBarangList}
                  loading={pemusnahanBarangResult.isLoading}
                  columns={[
                    ...dataTableColumn,
                    {
                      title: 'Actions',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      render: (text, record, index) => {
                        return (
                          <div className='flex justify-center items-center'>

                            <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ModalForm
                                update
                                initialValues={{ ...record, _method: 'PUT' }}
                                onSubmit={(e, callback) => handleUpdatePemusnahanBarang(e, callback)}
                                loading={updatePemusnahanBarangResult.isLoading}
                                barang={barangData?.data?.data || []}
                              />
                            </div>

                            <div className='flex bg-red-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ConfirmModal handleClick={() => handleDeletePemusnahanBarang(record)}>
                                <div className="flex items-center justify-center">
                                  <DeleteOutlined style={{ fontSize:15, color: "#fff" }}/>
                                  <span className="ml-1 text-xs text-white">Delete</span>
                                </div>
                              </ConfirmModal>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </Cards>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default PemusnahanBarangPage;
