import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

export const pemindahanService = createApi({
  reducerPath: 'pemindahanService',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', `Bearer ${getItem('access_token')}`);
      return headers;
    },
  }),
  tagTypes: ['jualBarang'],
  endpoints: (builder) => ({
    // jual
    getJualList: builder.mutation({
      query: (body) => ({
        url: `/pemindahan/jual/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['jualBarang'],
    }),
    getJual: builder.query({
      query: () => '/pemindahan/jual',
      providesTags: ['jualBarang'],
    }),
    getJualDetail: builder.query({
      query: (id_jual) => '/pemindahan/jual/' + id_jual,
      providesTags: ['jualBarang'],
    }),
    createJual: builder.mutation({
      query: (formData) => ({
        url: `/pemindahan/jual`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['jualBarang'],
    }),
    updateJual: builder.mutation({
      query: ({ formData, uuid }) => ({
        url: `/pemindahan/jual/${uuid}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['jualBarang'],
    }),
    deleteJual: builder.mutation({
      query: (body) => ({
        url: `/pemindahan/jual/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['jualBarang'],
    }),

    // hibah
    getHibahList: builder.mutation({
      query: (body) => ({
        url: `/pemindahan/hibah/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['hibahBarang'],
    }),
    getHibah: builder.query({
      query: () => '/pemindahan/hibah',
      providesTags: ['hibahBarang'],
    }),
    getHibahDetail: builder.query({
      query: (id_hibah) => '/pemindahan/hibah/' + id_hibah,
      providesTags: ['hibahBarang'],
    }),
    createHibah: builder.mutation({
      query: (formData) => ({
        url: `/pemindahan/hibah`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['hibahBarang'],
    }),
    updateHibah: builder.mutation({
      query: ({ formData, uuid }) => ({
        url: `/pemindahan/hibah/${uuid}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['hibahBarang'],
    }),
    deleteHibah: builder.mutation({
      query: (body) => ({
        url: `/pemindahan/hibah/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['hibahBarang'],
    }),

    // tukar
    getTukarList: builder.mutation({
      query: (body) => ({
        url: `/pemindahan/tukar/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['tukarBarang'],
    }),
    getTukar: builder.query({
      query: () => '/pemindahan/tukar',
      providesTags: ['tukarBarang'],
    }),
    getTukarDetail: builder.query({
      query: (id_tukar) => '/pemindahan/tukar/' + id_tukar,
      providesTags: ['tukarBarang'],
    }),
    createTukar: builder.mutation({
      query: (formData) => ({
        url: `/pemindahan/tukar`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['tukarBarang'],
    }),
    updateTukar: builder.mutation({
      query: ({ formData, uuid }) => ({
        url: `/pemindahan/tukar/${uuid}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['tukarBarang'],
    }),
    deleteTukar: builder.mutation({
      query: (body) => ({
        url: `/pemindahan/tukar/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['tukarBarang'],
    }),

    // pemusnahan
    getPemusnahanList: builder.mutation({
      query: (body) => ({
        url: `/pemindahan/pemusnahan/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['pemusnahanBarang'],
    }),
    getPemusnahan: builder.query({
      query: () => '/pemindahan/pemusnahan',
      providesTags: ['pemusnahanBarang'],
    }),
    getPemusnahanDetail: builder.query({
      query: (id_pemusnahan) => '/pemindahan/pemusnahan/' + id_pemusnahan,
      providesTags: ['pemusnahanBarang'],
    }),
    createPemusnahan: builder.mutation({
      query: (formData) => ({
        url: `/pemindahan/pemusnahan`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['pemusnahanBarang'],
    }),
    updatePemusnahan: builder.mutation({
      query: ({ formData, uuid }) => ({
        url: `/pemindahan/pemusnahan/${uuid}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['pemusnahanBarang'],
    }),
    deletePemusnahan: builder.mutation({
      query: (body) => ({
        url: `/pemindahan/pemusnahan/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['pemusnahanBarang'],
    }),
  }),
});

export const {
  // Jual
  useGetJualQuery,
  useGetJualDetailQuery,
  useGetJualListMutation,
  useCreateJualMutation,
  useUpdateJualMutation,
  useDeleteJualMutation,

  // Hibah
  useGetHibahQuery,
  useGetHibahDetailQuery,
  useGetHibahListMutation,
  useCreateHibahMutation,
  useUpdateHibahMutation,
  useDeleteHibahMutation,

  // Tukar
  useGetTukarQuery,
  useGetTukarDetailQuery,
  useGetTukarListMutation,
  useCreateTukarMutation,
  useUpdateTukarMutation,
  useDeleteTukarMutation,

  // Pemusnahan
  useGetPemusnahanQuery,
  useGetPemusnahanDetailQuery,
  useGetPemusnahanListMutation,
  useCreatePemusnahanMutation,
  useUpdatePemusnahanMutation,
  useDeletePemusnahanMutation,
} = pemindahanService;
