/* eslint-disable react/jsx-no-bind */
import { Button, Checkbox, Col, Form, Input, Row, Select, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import {
  useCreateBarangMutation,
  useGetBarangDetailQuery,
  useGetKategoriBarangQuery,
  useGetMetodePenyusutanQuery,
  useUpdateBarangMutation,
} from '../../../redux/service/masterService';
import { DataService } from '../../../config/dataService/dataService';
import axios from 'axios';
import { getItem } from '../../../utility/localStorageControl';

const PageRoutes = [
  {
    path: 'index',
    breadcrumbName: 'Barang',
  },
];

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;
function BarangFormPage() {
  const [form]        = Form.useForm();
  const navigate      = useNavigate();
  const { barang_id } = useParams();

  const [listLokasi, setListLokasi]       = useState([]);
  const [listKaryawan, setListKaryawan]   = useState([]);
  const [lokasiLoading, setLokasiLoading] = useState(false);

  const [fileListMultiple, setFileListMultiple]               = useState([]);
  const [imageLoading, setImageLoading]                       = useState(false);
  const [checkedMetodePenyusutan, setCheckedMetodePenyusutan] = useState(false);

  const { data: kategoryData, isLoading: kategoriLoading }                 = useGetKategoriBarangQuery();
  const { data: metodePenyusutanData, isLoading: metodePenyusutanLoading } = useGetMetodePenyusutanQuery();

  const listBarang                         = useGetBarangDetailQuery(barang_id);
  const [updateBarang, updateBarangResult] = useUpdateBarangMutation();
  const [createBarang, createBarangResult] = useCreateBarangMutation();
  const [kategoryDatas, setKategoryDatas]  = useState([])

  const barangDataOld         = listBarang?.data?.data || {};
  const metodePenyusutanDatas = metodePenyusutanData?.data || [];
  
  const handleCreateBarang = async (values) => {
    const formData = new FormData();
    fileListMultiple?.forEach((file) => {
      if (file.originFileObj) {
        formData.append('images[]', file.originFileObj);
      }
    });

    formData.append('nama_barang', values.nama_barang);
    formData.append('kondisi', values.kondisi);
    if (values.penyusutan_barang) {
      formData.append('penyusutan_barang', values.penyusutan_barang);
    }
    
    formData.append('metode_penyusutan_id', values.metode_penyusutan_id || 0);
    formData.append('masa_manfaat', values.masa_manfaat);
    formData.append('nilai_pertahun', values.nilai_pertahun);
    formData.append('kode_barang_resmi', values.kode_barang_resmi);
    formData.append('kode_barang', values.kode_barang);
    formData.append('nilai_perolehan', values.nilai_perolehan);
    formData.append('kategori_barang_id', values.kategori_barang_id);
    formData.append('tahun_pembelian', values.tahun_pembelian);
    formData.append('lokasi_id', values.lokasi_id);
    formData.append('karyawan_id', values.karyawan_id);

    if (values.keterangan) {
      formData.append('keterangan', values.keterangan);
    }

    if (barang_id) {
      try {
        const updateBarang = await DataService.post(`/barang/${barang_id}`, formData, {
          'Content-Type': 'multipart/form-data'
        })
        
        message.success(updateBarang.data.message);
        return navigate('/admin/barang');

      } catch (error) {
        return message.error(error?.data?.message !== undefined ? error?.data?.message : "Error update barang");
      }

    } else {
      try {
        const createBarang = await DataService.post(`/barang`, formData, {
          'Content-Type': 'multipart/form-data'
        })

        message.success(createBarang.data.message);
        form.resetFields();
        return navigate('/admin/barang');

      } catch (error) {
        return message.error(error?.data?.message !== undefined ? error?.data?.message : "Error create barang");
      }
    }
    
  };

  const handleChangeMultiple = ({ fileList: newFileList }) => {
    setFileListMultiple(newFileList);
  };

  const handleRemoveMultiple = async (file) => {
    console.log(file);
    try {
      const deletePhoto = await DataService.delete(`barang/delete-foto/${file.uid}`)
      if(deletePhoto.status === 200) {

        return message.success('Foto berhasil di hapus !');
      }
    
    } catch (error) {
      return message.error("Gagal menghapus foto !");
    }
  };

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const getDataLokasi = async () => {
    setLokasiLoading(true)

    try {
      const lokasiData = await DataService.get("/lokasi")
      if(lokasiData.status === 200) {
        let converData = []
        const result   = lokasiData.data.data

        for(let i=0; i<result.length; i++) {
          converData.push({
            label : result[i].nama_lokasi,
            value : result[i].id
          })
        }

        setListLokasi(converData)
      }

    } catch (error) {
      console.log("err => ", error.message);
    }

    setLokasiLoading(false)
  }

  const getDataKaryawan = async () => {
    setLokasiLoading(true)

    try {
      const karyawanData = await DataService.get("/karyawan")
      if(karyawanData.status === 200) {
        let converData = []
        const result   = karyawanData.data.data.data
        for(let i=0; i<result.length; i++) {
          converData.push({
            label : result[i].nama,
            value : result[i].id
          })
        }
        setListKaryawan(converData)
      }

    } catch (error) {
      console.log("err => ", error.message);
    }

    setLokasiLoading(false)
  }

  const getListKategori = async () => {
    setLokasiLoading(true)

    try {
      const kategori = await DataService.get("/master/kategori/barang")
      if(kategori.status === 200) {
        let converData = []
        const result   = kategori.data.data

        for(let i=0; i<result.length; i++) {
          converData.push({
            label : result[i].nama_kategori,
            value : result[i].id
          })
        }

        setKategoryDatas(converData)
      }

    } catch (error) {
      console.log("err => ", error.message);
    }

    setLokasiLoading(false)
  }

  const handlePlaceFormOnEdit = async () => {
    await getDataLokasi()
    await getDataKaryawan()
    await getListKategori()

    if (barang_id) {
      if (listBarang.isSuccess) {
        const images = barangDataOld?.foto_barang?.map((item) => {
          return {
            uid: item.id,
            status: 'done',
            url: item.foto_barang,
          };
        });

        setFileListMultiple(images);
        form.setFieldsValue(barangDataOld);
        form.setFieldValue("karyawan_id", parseInt(barangDataOld.karyawan_id))
        form.setFieldValue("lokasi_id", parseInt(barangDataOld.lokasi_id))
        form.setFieldValue("kategori_barang_id", parseInt(barangDataOld.kategori_barang_id))
      }
    }
  }

  useEffect(() => {
    handlePlaceFormOnEdit()
  }, [listBarang.isSuccess, barang_id]);

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title={barang_id === undefined ? "Tambah Barang" : "Edit Barang"}
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={(value) => handleCreateBarang(value)}
          autoComplete="off"
        >
          <Cards title="Data Barang">
            <Row gutter={15}>
              <Col xs={12}>
                <Form.Item
                  label="Nama Barang"
                  name="nama_barang"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Nama Barang!',
                    },
                  ]}
                >
                  <Input size="small" placeholder='Masukan Nama Barang' style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>

                <Form.Item
                  label="Kondisi"
                  name="kondisi"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Kondisi!',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Pilih Kondisi"
                    size="medium"
                    name="kondisi"
                    className="h-[37px]"
                    loading={kategoriLoading}
                    options={[
                      {
                        value : "rusak ringan",
                        label : "Rusak Ringan"
                      },
                      {
                        value : "rusak berat",
                        label : "Rusak Berat"
                      },
                      {
                        value : "baik",
                        label : "Baik"
                      }
                    ]}
                    rules={[
                      {
                        required: true,
                        message: 'Please input Kondisi!',
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Tahun Pembelian"
                  name="tahun_pembelian"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Tahun Pembelian!',
                    },
                  ]}
                >
                  <Input size="small" placeholder='Masukan Tahun Pembelian' style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>

                <Form.Item
                  label="Lokasi"
                  name="lokasi_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Lokasi!',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Pilih Lokasi"
                    size="medium"
                    className="h-[37px]"
                    options={listLokasi}
                    loading={lokasiLoading}
                  />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item
                  label="Kode Barang"
                  name="kode_barang"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Kode Barang!',
                    },
                  ]}
                >
                  <Input size="small" placeholder='Masukan Kode Barang' style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>
                <Form.Item
                  label="Kode Resmi Barang"
                  name="kode_barang_resmi"
                  rules={[
                    {
                      required: false
                    },
                  ]}
                >
                  <Input size="small" name='kode_barang_resmi' placeholder='Masukan Kode Resmi Barang' style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>
                <Form.Item
                  label="Nilai Perolehan"
                  name="nilai_perolehan"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Nilai Perolehan!',
                    },
                  ]}
                >
                  <Input type="number" placeholder='Masukan Nilai Perolehan' size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>
                <Form.Item
                  label="Kategori"
                  name="kategori_barang_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Kategori!',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Pilih Kategori"
                    size="medium"
                    className="h-[37px]"
                    loading={kategoriLoading}
                    options={kategoryDatas}
                  />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item label="Keterangan" name="keterangan">
                  <Input size="small" placeholder='Masukan Keterangan' style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Karyawan"
                  name="karyawan_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please Input karyawan!',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    name="karyawan_id"
                    placeholder="Pilih karyawan"
                    size="small"
                    loading={lokasiLoading}
                    options={listKaryawan}
                    className="h-[37px]"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Cards>

          {/* penyusutan */}
          <Cards title="Penyusutan" className={'mt-4'}>
            <Row gutter={15}>
              <Col xs={12}>
                <Form.Item
                  label="Masa Manfaat (tahun)"
                  name="masa_manfaat"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Masa Manfaat!',
                    },
                  ]}
                >
                  <Input type="number" size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item label="Barang Non-depresiasi" name="penyusutan_barang">
                  <Checkbox
                    size="small"
                    style={{ paddingTop: 6, paddingBottom: 6 }}
                    checked={checkedMetodePenyusutan || barangDataOld?.penyusutan_barang > 0}
                    onChange={() => setCheckedMetodePenyusutan(!checkedMetodePenyusutan)}
                  >
                    Ceklis jika barang tidak mengalami penyusutan
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item
                  label="Metode Penyusutan"
                  name="metode_penyusutan_id"
                  rules={[
                    {
                      required: !checkedMetodePenyusutan,
                      message: 'Please input Metode Penyusutan!',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Pilih Metode Penyusutan"
                    size="medium"
                    className="h-[37px]"
                    loading={metodePenyusutanLoading}
                    disabled={checkedMetodePenyusutan}
                    onChange={(e) => {
                      const penyusutan = metodePenyusutanDatas.find((item) => item.id == e);
                      const nama_penyusutan = penyusutan?.nama_penyusutan?.toLowerCase()?.replace(' ', '-');
                      const masaManfaat = parseInt(form.getFieldValue('masa_manfaat'));
                      const nilaiPerolehan = parseInt(form.getFieldValue('nilai_perolehan'));
                      if (nama_penyusutan === 'garis-lurus') {
                        if (nilaiPerolehan > 0 && masaManfaat > 0) {
                          const nilai = nilaiPerolehan / masaManfaat;
                          // const finalNilai = nilai > 0 ? nilai / 12 : 0;

                          form.setFieldValue('nilai_pertahun', nilai);
                        }
                      }

                      if (nama_penyusutan === 'nilai-menurun') {
                        if (nilaiPerolehan > 0 && masaManfaat > 0) {
                          const nilai = nilaiPerolehan / 2;
                          const finalNilai = nilai > 0 ? nilai / masaManfaat : 0;

                          form.setFieldValue('nilai_pertahun', finalNilai);
                        }
                      }
                    }}
                  >
                    {metodePenyusutanDatas.map((item) => (
                      <Select.Option key={item.id} value={`${item.id}`}>
                        {item?.nama_penyusutan}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>


              <Col xs={12}>
                <Form.Item label="Nilai Pertahun" name="nilai_pertahun">
                  <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>
              </Col>
            </Row>
          </Cards>

          {/* foto barang */}
          <Cards title="Foto Aset" className={'mt-4'}>
            <Row gutter={15}>
              <Col xs={24}>
                <Form.Item
                  label="Foto Aset (multiple)"
                  name="images"
                  rules={[
                    {
                      required: barang_id ? false : true,
                      message: 'Please input Images!',
                    },
                  ]}
                >
                  <Upload
                    name="images"
                    className="avatar-uploader"
                    multiple={true}
                    beforeUpload={() => false}
                    listType="picture-card"
                    fileList={fileListMultiple}
                    onChange={handleChangeMultiple}
                    onRemove={handleRemoveMultiple}
                  >
                    {fileListMultiple?.length >= 8 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Button
                  onClick={() => form.submit()}
                  type="primary"
                  className="bg-blue-600 float-right"
                  loading={createBarangResult?.isLoading || updateBarangResult?.isLoading}
                >
                  <span className="ml-2">Simpan</span>
                </Button>
              </Col>
            </Row>
          </Cards>
        </Form>
      </main>
    </>
  );
}

export default BarangFormPage;
