import Cards from "antd/es/card/Card"
import { Button, Col, Row, Modal, Form, Upload, Input, message, Pagination, Table, Select } from "antd"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { EditOutlined, PlusOutlined, InboxOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons"
import { DataService } from "../../../../config/dataService/dataService"
import { useForm } from "antd/es/form/Form"
import ConfirmModal from "../../../../components/atoms/ConfirmModal"
import { useDeleteBarangMutation } from "../../../../redux/service/masterService"
import moment from "moment"
import { listBiro } from "../../../../config/dataStatic"

const { Dragger } = Upload;
const { Search }  = Input

const DetailEmploye = () => {
    const { id }    = useParams()
    const [ form ]  = useForm()
    const navigate  = useNavigate()
    const [deleteBarang, deleteBarangResult] = useDeleteBarangMutation();
    const [loadingBarang, setLoadingBarang] = useState(false)
    const [dataEmployes, setDataEmployes] = useState({})
    const [dataBarang, setDataBarang]     = useState([])
    const [showModals, setShowModals]     = useState(false)
    const [showModalsCreateAndUpdate, setShowModalsCreateAndUpdate] = useState({ id:"", type:"", status:false })
    const [imageEdit, setImageEdit] = useState("")
    const [pageSize, setPageSize]   = useState(10);
    const [dataBarangSearch, setDataBarangSearch]       = useState([])
    const [loadingSearchBarang, setLoadingSearchBarang] = useState(false)
    const [pagesBarangData, setpagesBarangData]     = useState({
        current_page : 1,
        total_page   : 0
    })

    const dataTableColumn = [
        {
            title: 'No.',
            dataIndex: 'nomor',
            key: 'nomor',
            render: (text, record, index) => ((pagesBarangData.current_page !== undefined ? pagesBarangData.current_page : 1) - 1) * pageSize + index + 1
        },
        {
            title: 'Nama Barang',
            dataIndex: 'nama_barang',
            key: 'nama_barang',
        },
        {
            title: 'Kategori',
            dataIndex: 'nama_kategori',
            key: 'nama_kategori',
        },
        {
            title: 'Lokasi',
            render: (_, text) => (
                <span>{text.lokasi.nama_lokasi}</span>
            )
        },
        {
            title: 'Tanggal',
            render: (_, text) => (
                moment(text.createdAt).format("DD/MM/YYYY")
            )
        },
    ];

    const handleDeleteBarang = (record) => {
        deleteBarang({ uuid: record.uuid }).then(({ data, error }) => {
            if (data) {
                message.success('Data Berhasil Dihapus');
                return proccessFilterData();
            }

            message.error('Data Gagal Dihapus');
        });
    };

    const employe = async () => {
        try {
            const dataEmploye = await DataService.get(`karyawan/${id}`)
            setDataEmployes(dataEmploye.data.data)
            barang(dataEmploye.data.data.id)

        } catch (error) {
            message.error("Gagal mengambil data karyawan !")
        }
    }

    const barang = async (idEmploye) => {
        setLoadingBarang(true)
        try {
            const data = await DataService.get(`barang?karyawan_id=${idEmploye}`)
            setDataBarang(data.data.data.data)
            setpagesBarangData(data.data.data.total)

        } catch (error) {
            message.error("Gagal mengambil data barang !")

        } finally {
            setLoadingBarang(false)
        }
    }

    const showModalsEdit = (record) => {
        if(record.foto !== "") {
            setImageEdit(record.foto)
        }

        form.setFieldValue("nama", record.nama)
        form.setFieldValue("nip", record.nip)
        form.setFieldValue("instansi", record.instansi)
        form.setFieldValue("jabatan", record.jabatan)
        setShowModalsCreateAndUpdate({ status:true, type:"edit", id: record.id })
    }

    const updateAndCreateEmploye = async (value, type) => {
        try {
            const postData = new FormData()
            if(value.foto !== undefined) {
                postData.append("foto", value.foto.file)
            }

            postData.append("nama", value.nama)
            postData.append("nip", value.nip)
            postData.append("instansi", value.instansi)
            postData.append("jabatan", value.jabatan)

            if(type === "create") {
                await DataService.post("/karyawan", postData, {
                    'Content-Type': 'multipart/form-data'
                })

                message.success("Berhasil di tambahkan !")

            } else {
                await DataService.post(`/karyawan/${showModalsCreateAndUpdate.id}`, postData, {
                    'Content-Type': 'multipart/form-data'
                })

                message.success("Berhasil di perbarui !")
            }

            setShowModalsCreateAndUpdate({
                status : false,
                id     : "",
                type   : "create"
            })

            employe()
            setImageEdit("")
            form.resetFields()

        } catch (error) {
            if(error !== undefined) {
                message.error(error?.message !== undefined ? error?.message : error.data.message);
            }
        }
    }

    const proccessFilterData = async (pages=1, pageSize=10) => {
        barang(id, pages, pageSize)
        setPageSize(pageSize)
        setpagesBarangData({
            current_page : pages
        })
    }

    const handleUpdateAssign = async (idEmploye, idBarang) => {
        try {
            const formData = new FormData()
            formData.append("karyawan_id", idEmploye)

            await DataService.post(`/barang/${idBarang}`, formData, {
                'Content-Type': 'multipart/form-data'
            })

            setShowModals(false)
            setDataBarangSearch([])
            barang(idEmploye)
            message.success("Barang di tambahkan")

        } catch (error) {
            setShowModals(false)
            setDataBarangSearch([])

            message.error("Barang gagal di tambahkan")
            console.log("error : ", error);
        }
    }

    const searchBarang = async (kode_barang) => {
        setLoadingSearchBarang(true)
        try {
            const data = await DataService.get(`barang?kode_barang=${kode_barang}`)
            setDataBarangSearch(data.data.data.data)

        } catch (error) {
            console.log("error => ", error);

        } finally {
            setLoadingSearchBarang(false)
        }
    }

    useEffect(() => {
        employe()
    }, [id])

    return (
        <div className="flex flex-col px-4 min-h-screen pb-5">
            <Cards title="Detail Karyawan" className={'mt-4'} extra={<Button icon={<EditOutlined/>} onClick={() => showModalsEdit(Object.keys(dataEmployes).length > 0 && dataEmployes)}>Edit</Button>}>
                {
                    Object.keys(dataEmployes).length > 0 &&
                    <>
                        <Row>
                            <Col xs={24} className='p-1'>
                                <div className='flex w-full flex-col my-2 justify-center items-center mb-4'>
                                    <img src={dataEmployes.foto} alt="profile" style={{ width:250 }}/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={12} className='p-1'>
                                <div className='flex w-full flex-col my-2'>
                                    <div className='flex w-full'>
                                        <span className='font-medium text-gray-400 text-xs'>Nama</span>
                                    </div>
                                    <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                                        <span className='text-md'>
                                            {dataEmployes.nama}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} className='p-1'>
                                <div className='flex w-full flex-col my-2'>
                                    <div className='flex w-full'>
                                        <span className='font-medium text-gray-400 text-xs'>NIP</span>
                                    </div>
                                    <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                                        <span className='text-md'>
                                            {dataEmployes.nip}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={12} className='p-1'>
                                <div className='flex w-full flex-col my-2'>
                                    <div className='flex w-full'>
                                        <span className='font-medium text-gray-400 text-xs'>Divisi/Biro</span>
                                    </div>
                                    <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                                        <span className='text-md'>
                                            {dataEmployes.instansi}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} className='p-1'>
                                <div className='flex w-full flex-col my-2'>
                                    <div className='flex w-full'>
                                        <span className='font-medium text-gray-400 text-xs'>Jabatan</span>
                                    </div>
                                    <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                                        <span className='text-md'>
                                            {dataEmployes.jabatan}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </Cards>

            <Cards title="Data Barang" className={'mt-4'}  extra={<Button icon={<PlusOutlined/>} onClick={() => setShowModals(true)}>Tambah Barang</Button>}>
                <div className="overflow-x-auto max-w-[100%]">
                    <Table
                    pagination={false}
                    dataSource={dataBarang}
                    loading={loadingBarang}
                    rowKey='id'
                    columns={[
                        ...dataTableColumn,
                        {
                        title: 'Actions',
                        dataIndex: 'action',
                        key: 'action',
                        align: 'center',
                        render: (text, record, index) => {
                            return (
                            <div className='flex items-center justify-center'>

                                <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                                    <p onClick={() => navigate('/admin/barang/detail/' + record.uuid)}>
                                        <div className="flex items-center justify-center">
                                        <EyeOutlined style={{ fontSize:15, color: "#fff"}}/>
                                        </div>
                                    </p>
                                </div>

                                <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                                    <p onClick={() => navigate('/admin/barang/form/' + record.uuid)}>
                                        <div className="flex items-center justify-center">
                                            <EditOutlined style={{ fontSize:15, color: "#fff" }}/>
                                            <span className='text-xs text-white ml-1'>
                                            Edit
                                            </span>
                                        </div>
                                    </p>
                                </div>

                                <div className='flex bg-red-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                                    <ConfirmModal handleClick={() => handleDeleteBarang(record)}>
                                        <div className="flex items-center justify-center">
                                        <DeleteOutlined style={{ fontSize:15, color: "#fff" }}/>
                                        <span className="ml-1 text-xs text-white">Delete</span>
                                        </div>
                                    </ConfirmModal>
                                </div>
                            </div>
                            );
                        },
                        },
                    ]}
                    />

                    <Pagination
                        showSizeChanger
                        pageSize={pageSize}
                        defaultCurrent={1}
                        current={pagesBarangData?.current_page}
                        total={pagesBarangData?.total}
                        className="mt-4 mx-auto"
                        onChange={(e, pageSize) => proccessFilterData(e, pageSize)}
                    />
                </div>
            </Cards>


            {/* modals edit */}
            <Modal 
                cancelText="Tutup"
                title={`${showModalsCreateAndUpdate.type === "create" ? "Tambah" : "Ubah"} Data Karyawan`} 
                open={showModalsCreateAndUpdate.status} 
                onOk={() => { form.submit() }} 
                onCancel={() => {
                setShowModalsCreateAndUpdate({ status:false, type : "create" })

                setImageEdit("")
                form.resetFields()
                }} 
            >
                <div className="flex flex-col mt-5">
                    <Form
                        form={form}
                        name="basic"
                        layout="vertical"
                        onFinish={(value) => updateAndCreateEmploye(value, showModalsCreateAndUpdate.type)}
                        autoComplete="off"
                    >
                        {
                            (showModalsCreateAndUpdate.type !== "create" && imageEdit !== "") &&
                            <div className="flex justify-center flex-col mb-3">
                                <div className="flex">
                                    <span>Foto sekarang</span>
                                </div>
                                <div className="flex mt-2 justify-center">
                                    <img src={imageEdit} className='rounded-md' style={{ height:150 }}/>
                                </div>
                            </div>
                        }
                        
                        <Form.Item
                            label={`${showModalsCreateAndUpdate.type !== "create" ? "Ubah" : "" } Foto`}
                            name="foto"
                            rules={[
                                {
                                required: showModalsCreateAndUpdate.type === "create" ? true : false,
                                message: 'Masukan foto !'
                                }
                            ]}
                        >
                            <Dragger beforeUpload={() => false}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klik atau tarik foto ke area ini</p>
                            </Dragger>
                        </Form.Item>

                        <Form.Item
                            label="Nama"
                            name="nama"
                            rules={[
                                {
                                required: true,
                                message: 'Masukan nama !',
                                },
                            ]}
                        >
                            <Input size="small" placeholder='Masukan Nama' style={{ paddingTop: 6, paddingBottom: 6 }} />
                        </Form.Item>

                        <Form.Item
                            label="NIP"
                            name="nip"
                            rules={[
                                {
                                required: true,
                                message: 'Masukan nip !',
                                },
                            ]}
                        >
                            <Input size="small" placeholder='Masukan nip' style={{ paddingTop: 6, paddingBottom: 6 }} />
                        </Form.Item>

                        <Form.Item
                            label="Devisi/Biro"
                            name="instansi"
                            rules={[
                                {
                                required: true,
                                message: 'Masukan devisi atau instansi !',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Pilih Devisi/Biro"
                                size="medium"
                                name="instansi"
                                className="h-[37px]"
                                options={listBiro}
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input Devisi/Biro!',
                                },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Jabatan"
                            name="jabatan"
                            rules={[
                                {
                                required: true,
                                message: 'Masukan jabatan !',
                                },
                            ]}
                        >
                            <Input size="small" placeholder='Masukan jabatan' style={{ paddingTop: 6, paddingBottom: 6 }} />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>

            {/* modals tambah barang */}
            <Modal title={`Tambahkan barang ke ${dataEmployes.nama}`} open={showModals} onOk={() => { handleUpdateAssign(id) }} onCancel={() => { setShowModals(false) }} cancelText="Tutup">
                <div className="flex flex-col">
                    <div className="flex mt-2">
                        <Search
                            size="large"
                            placeholder="Cari kode barang..."
                            allowClear
                            onSearch={searchBarang}
                            loading={loadingSearchBarang}
                        />
                    </div>
                    <div className="flex my-2 flex-col">
                        {
                            dataBarangSearch.length > 0 ?
                                dataBarangSearch.map((e, i) => (
                                    <div className="flex items-center my-2" key={i}>
                                        <div className="flex w-full">
                                            <span className="text-ellipsis">{e.nama_barang}</span>
                                        </div>
                                        <div className="flex w-full justify-center">
                                            <span>{e.kode_barang}</span>
                                        </div>
                                        <div className="flex w-full justify-end">
                                            <Button icon={<PlusOutlined color="#ffffff"/>} type="primary" onClick={() => handleUpdateAssign(dataEmployes.id, e.uuid)}>Tambahkan</Button>
                                        </div>
                                    </div>

                                ))
                            :
                                <div className="flex justify-center items-center w-full py-5">
                                    <span>Barang tidak di temukan, atau masukan kode barang yang benar ...</span>
                                </div>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DetailEmploye