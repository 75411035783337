import { Menu } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@ant-design/compatible';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { useGetMenuQuery } from '../redux/service/userService';

function MenuItems({ toggleCollapsed }) {

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState('dashboard');

  const menu = useGetMenuQuery();
  const menus = menu?.data?.data || [];

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1]);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) return setOpenKeys([item.keyPath[0]]);
    setOpenKeys([item.keyPath[1]]);
  };

  const items = menus.map((item) => {
    const hasChildren = item.children.length > 0;
    if (hasChildren) {
      const childrens = item.children.map((child) =>
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${child.menu_route}`}>
            {child.menu_label}
          </NavLink>,
          child.menu_route,
          null,
        ),
      );

      return getItem(
        item.menu_label, 
        item.menu_route, 
        <div className='h-[15px] w-[15px]'>
          <Icon type={item.menu_icon} style={{ fontSize: '15px', color: '#08c' }} theme="outlined" />
        </div>,
        childrens
      );
    }

    return getItem(
      <NavLink onClick={toggleCollapsed} to={`${item.menu_route}`}>
        {item.menu_label}
      </NavLink>,
      item.menu_route,
      <div className='h-[15px] w-[15px]'>
        <Icon type={item.menu_icon} style={{ fontSize: '15px', color: '#08c' }} theme="outlined" />
      </div>
    );
  });

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={'inline'}
      selectedKeys={[`${mainPathSplit.length > 2 ? `${mainPathSplit[1]}/${mainPathSplit[2]}` : mainPathSplit[1]}`]}
      defaultSelectedKeys={[`${mainPathSplit.length > 2 ? mainPathSplit[1] : ['dashboard']}`]}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={[`${mainPathSplit.length > 3 ? mainPathSplit[1] : openKeys}`]}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
