import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Modal } from '../../../../components/modals/antd-modals';
import moment from 'moment';

const ModalForm = ({ initialValues = {}, update = false, onSubmit, barang = [] }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    onSubmit({ ...initialValues, ...value, tanggal: value.tanggal.format('YYYY-MM-DD') }, () => setIsModalOpen(false));
  };

  return (
    <>
      {update ? (
        <button onClick={() => showModal()} className="cursor-pointer">
          <div className="flex items-center justify-center">
              <EditOutlined style={{ fontSize:15, color: "#fff" }}/>
              <span className='text-xs text-white ml-1'>
                Edit
              </span>
          </div>
        </button>
      ) : (
        <Button onClick={() => showModal()} type="primary" className="bg-blue-600 flex items-center">
          <PlusOutlined color="#fff" />
          <span className="ml-2">Jadwal</span>
        </Button>
      )}
      <Modal
        title={update ? 'Edit Jadwal' : 'Tambah Jadwal'}
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{
            ...initialValues,
            tanggal: moment(initialValues?.tanggal ?? new Date(), 'YYYY-MM-DD'),
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={24}>
              <Form.Item
                label="Barang"
                name="barang_id"
                rules={[
                  {
                    required: true,
                    message: 'Please input Barang!',
                  },
                ]}
              >
                <Select allowClear placeholder="Pilih Barang" size="medium" style={{ paddingTop: 4, paddingBottom: 4 }}>
                  {barang.map((item) => (
                    <Select.Option key={item.id} value={`${item.id}`}>
                      {item.nama_barang}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Nama Jadwal"
                name="judul"
                rules={[
                  {
                    required: true,
                    message: 'Please input Nama Jadwal!',
                  },
                ]}
              >
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
              <Form.Item
                label="Tanggal Pengecekan"
                name="tanggal"
                rules={[
                  {
                    required: true,
                    message: 'Please input Tanggal Pengecekan!',
                  },
                ]}
              >
                <DatePicker size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
              <Form.Item label="Keterangan" name="keterangan">
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;
