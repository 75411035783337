import { Col, Row, Table, message } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteOutlined } from "@ant-design/icons"
import ConfirmModal from '../../../../components/atoms/ConfirmModal';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../../components/page-headers/page-headers';
import {
  useCreateTukarMutation,
  useDeleteTukarMutation,
  useGetTukarListMutation,
  useUpdateTukarMutation,
} from '../../../../redux/service/pemindahanService';
import ModalForm from './components/ModalForm';
import { dataTableColumn } from './config';
import { useGetBarangQuery } from '../../../../redux/service/masterService';

const PageRoutes = [
  {
    path: 'index',
    breadcrumbName: 'Pemindahan',
  },
  {
    path: 'pemindahan/tukar',
    breadcrumbName: 'Tukar Barang',
  },
];
function TukarBarangPage() {
  const dispatch = useDispatch();

  const { data: barangData, isLoading: barangLoading } = useGetBarangQuery();
  const [listHibah, tukarBarangResult] = useGetTukarListMutation();
  const [updateTukarBarang, updateTukarBarangResult] = useUpdateTukarMutation();
  const [createTukarBarang, createTukarBarangResult] = useCreateTukarMutation();
  const [deleteTukarBarang, deleteTukarBarangResult] = useDeleteTukarMutation();

  useEffect(() => {
    listHibah();
  }, []);

  const tukarBarangList = tukarBarangResult?.data?.data?.data || [];
  const handleCreateTukarBarang = (e, callback) => {
    createTukarBarang(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.data?.message);
      }
      callback();
      message.success(data?.message);
      return listHibah();
    });
  };

  const handleUpdateTukarBarang = (e, callback) => {
    updateTukarBarang({ formData: e, uuid: e.uuid }).then(({ error, data }) => {
      if (error) {
        return message.error(error?.message);
      }
      callback();
      message.success(data?.message);
      return listHibah();
    });
  };

  const handleDeleteTukarBarang = (record) => {
    deleteTukarBarang({ uuid: record.uuid }).then(({ data, error }) => {
      if (data) {
        message.success('Data Berhasil Dihapus');
        return listHibah();
      }

      message.error('Data Gagal Dihapus');
    });
  };

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Mutasi Barang"
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={15}>
          <Col xs={24}>
            <div>
              <Cards
                title="List tukar Barang"
                isbutton={
                  <ModalForm
                    onSubmit={(e, callback) => handleCreateTukarBarang(e, callback)}
                    loading={createTukarBarangResult.isLoading}
                    barang={barangData?.data?.data || []}
                  />
                }
              >
                <Table
                  pagination={false}
                  dataSource={tukarBarangList}
                  loading={tukarBarangResult.isLoading}
                  columns={[
                    ...dataTableColumn,
                    {
                      title: 'Actions',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      render: (text, record, index) => {
                        return (
                          <div className='flex justify-center items-center'>

                            <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ModalForm
                                update
                                initialValues={{ ...record, _method: 'PUT' }}
                                onSubmit={(e, callback) => handleUpdateTukarBarang(e, callback)}
                                loading={updateTukarBarangResult.isLoading}
                                barang={barangData?.data?.data || []}
                              />
                            </div>

                            <div className='flex bg-red-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ConfirmModal handleClick={() => handleDeleteTukarBarang(record)}>
                                <div className="flex items-center justify-center">
                                  <DeleteOutlined style={{ fontSize:15, color: "#fff" }}/>
                                  <span className="ml-1 text-xs text-white">Delete</span>
                                </div>
                              </ConfirmModal>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </Cards>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default TukarBarangPage;
