import { Card, Button, Row, Col, Table, Pagination, Modal, message } from "antd"
import { PlusOutlined, EditOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { DataService } from "../../../../../config/dataService/dataService"
import { useNavigate, useParams } from "react-router-dom"
import { QRCode } from "react-qrcode-logo"
import moment from "moment"
import { formatNumber } from "../../../../../utility/utility"
import Search from "antd/es/input/Search"

const CheckBarang = () => {
    const { id }   = useParams()
    const navigate = useNavigate()
    const [detailLokasi, setDetailLokasi] = useState({})
    const [loading, setLoading]           = useState(false)
    const [showModals, setShowModals]     = useState(false)
    const [loadingSearchBarang, setLoadingSearchBarang] = useState(false)
    const [loadingChangeLocation, setLoadingChangeLocation] = useState(false)
    const [dataBarang, setDataBarang]     = useState([])
    const [dataBarangSearch, setDataBarangSearch]  = useState([])
    const [loadingBarang, setLoadingBarang] = useState(false)
    const [pagesBarangData, setpagesBarangData]  = useState({
        current_page : 1,
        total_page   : 0
    })

    const dataTableColumn = [
        {
            title: 'No.',
            dataIndex: 'nomor',
            key: 'nomor',
            render: (text, record, index) => ((pagesBarangData.current_page !== undefined ? pagesBarangData.current_page : 1) - 1) * 10 + index + 1
        },
        {
            title: 'Nama Barang',
            dataIndex: 'nama_barang',
            key: 'nama_barang',
        },
        {
            title: 'Kode Barang',
            dataIndex: 'kode_barang',
            key: 'kode_barang',
        },
        {
            title: 'Tahun Pembelian',
            dataIndex: 'tahun_pembelian',
            key: 'tahun_pembelian',
        },
        {
            title: 'Nilai Perolehan',
            dataIndex: 'nilai_perolehan',
            key: 'nilai_perolehan',
            render : (_, text) => (
                <span>{formatNumber(text.nilai_perolehan)}</span>
            )
        },
        {
            title: 'Kondisi',
            dataIndex: 'kondisi',
            key: 'kondisi',
            render : (_, text) => (
                <span className="uppercase">{text.kondisi}</span>
            )
        }
    ];

    const barang = async (lokasiId="") => {
        setLoadingBarang(true)
        try {
            const data = await DataService.get(`barang?lokasi_id=${lokasiId}`)
            setDataBarang(data.data.data.data)
            setpagesBarangData(data.data.data.total)

        } catch (error) {
            message.error("Gagal mengambil data barang !")

        } finally {
            setLoadingBarang(false)
        }
    }

    const getLokasiDetail = async () => {
        setLoading(true)
        try {
            const lokasi = await DataService.get(`/lokasi/${id}`)
            const result = lokasi.data.data
            setDetailLokasi(result)
            barang(result.id)
            history(result.id)

        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }

    const proccessFilterDataBarang = (page=1) => {

    }

    const handleUpdateLocationBarang = async (idLocation, idBarang) =>{
        setLoadingChangeLocation(true)
        try {
            const formData = new FormData()
            formData.append("lokasi_id", idLocation)

            await DataService.post(`/barang/${idBarang}`, formData, {
                'Content-Type': 'multipart/form-data'
            })

            setShowModals(false)
            setDataBarangSearch([])
            barang(idLocation)
            message.success("Barang di tambahkan")

        } catch (error) {
            setShowModals(false)
            setDataBarangSearch([])

            message.error("Barang gagal di tambahkan")
            console.log("error : ", error);

        } finally {
            setLoadingChangeLocation(false)
        }
    }

    const searchBarang = async (kode_barang) => {
        setLoadingSearchBarang(true)
        try {
            const data = await DataService.get(`barang?kode_barang=${kode_barang}`)
            setDataBarangSearch(data.data.data.data)

        } catch (error) {
            console.log("error => ", error);

        } finally {
            setLoadingSearchBarang(false)
        }
    }

    useEffect(() => {
        getLokasiDetail()
    }, [])

    return (
        <div className="flex min-h-screen w-full px-4 flex-col">
            <Card title="Detail Lokasi" className="mt-4 mb-4 w-full">
            {
                loading ?
                    <div className="flex w-full justify-center py-2">
                        <span>Loading ...</span>
                    </div>
                :
                    Object.keys(detailLokasi).length > 0 &&
                    <>
                        <Row>
                            <Col xs={24} className='p-1'>
                                <div className='flex w-full flex-col my-2 justify-center items-center mb-4'>
                                    <QRCode value={detailLokasi.kode_lokasi} size={100}/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={12} className='p-1'>
                                <div className='flex w-full flex-col my-2'>
                                    <div className='flex w-full'>
                                        <span className='font-medium text-gray-400 text-xs'>Nama</span>
                                    </div>
                                    <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                                        <span className='text-md'>
                                            {detailLokasi.nama_lokasi}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} className='p-1'>
                                <div className='flex w-full flex-col my-2'>
                                    <div className='flex w-full'>
                                        <span className='font-medium text-gray-400 text-xs'>Kode Lokasi</span>
                                    </div>
                                    <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                                        <span className='text-md'>
                                            {detailLokasi.kode_lokasi}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
            }
            </Card>

            <Card title="Daftar Barang" className="mt-4 mb-4 w-full"  extra={<Button icon={<PlusOutlined/>} onClick={() => setShowModals(true)}>Barang Baru</Button>}>
                <div className="overflow-x-auto max-w-[100%]">
                    <Table
                        pagination={false}
                        dataSource={dataBarang}
                        loading={loadingBarang}
                        rowKey='id'
                        columns={[
                            ...dataTableColumn,
                            {
                                title: 'Actions',
                                render: (_, text) => (
                                    <Button 
                                        type="primary" 
                                        onClick={() => navigate(`/admin/lokasi/detail/check-barang/perbarui-barang/${text.uuid}`)} 
                                        disabled={moment(text.check_date).isSame(moment(), 'day') ? true : false} 
                                        icon={moment(text.check_date).isSame(moment(), 'day') ? "" : <EditOutlined/>} 
                                        className={`${moment(text.check_date).isSame(moment(), 'day') ? "text-blue-500" : "text-white"}`}
                                    >
                                        {moment(text.check_date).isSame(moment(), 'day') ? "Diperbarui" : "Perbarui"}
                                    </Button>
                                )
                            }
                        ]}
                    />

                    <Pagination
                        defaultCurrent={1}
                        current={pagesBarangData?.current_page}
                        total={pagesBarangData?.total}
                        className="mt-4 mx-auto"
                        onChange={(e) => proccessFilterDataBarang(e)}
                    />
                </div>
            </Card>

            {/* modals  */}
            <Modal title={`Tambahkan barang ke ${detailLokasi.nama_lokasi}`} open={showModals} onOk={() => { handleDelete(id) }} onCancel={() => { setShowModals(false) }} cancelText="Tutup">
                <div className="flex flex-col">
                    <div className="flex mt-2">
                        <Search
                            size="large"
                            placeholder="Cari kode barang..."
                            allowClear
                            onSearch={searchBarang}
                            loading={loadingSearchBarang}
                        />
                    </div>
                    <div className="flex my-2 flex-col">
                        {
                            dataBarangSearch.length > 0 ?
                                dataBarangSearch.map((e, i) => (
                                    <div className="flex items-center my-2" key={i}>
                                        <div className="flex w-full">
                                            <span className="text-ellipsis">{e.nama_barang}</span>
                                        </div>
                                        <div className="flex w-full justify-center">
                                            <span>{e.kode_barang}</span>
                                        </div>
                                        <div className="flex w-full justify-end">
                                            <Button icon={<PlusOutlined color="#ffffff"/>} type="primary" onClick={() => handleUpdateLocationBarang(detailLokasi.id, e.uuid)}>Tambahkan</Button>
                                        </div>
                                    </div>

                                ))
                            :
                                <div className="flex justify-center items-center w-full py-5">
                                    <span>Barang tidak di temukan, atau masukan kode barang yang benar ...</span>
                                </div>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CheckBarang