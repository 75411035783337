import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Modal } from '../../../../../components/modals/antd-modals';
const ModalForm = ({ initialValues = {}, update = false, onSubmit, parents = [] }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    form.resetFields();
    onSubmit({ ...initialValues, ...value }, () => setIsModalOpen(false));
  };

  const parentMenu = parents.filter((item) => !item.parent_id);
  return (
    <>
      {update ? (
        <button onClick={() => showModal()} className="cursor-pointer">
          <div className="flex items-center justify-center">
              <EditOutlined style={{ fontSize:15, color: "#fff" }}/>
              <span className='text-xs text-white ml-1'>
                Edit
              </span>
          </div>
        </button>
      ) : (
        <Button onClick={() => showModal()} type="primary" className="bg-blue-600 flex items-center">
          <PlusOutlined color="#fff" />
          <span className="ml-2">Lokasi</span>
        </Button>
      )}
      <Modal
        title={update ? 'Edit Lokasi' : 'Tambah Lokasi'}
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{
            ...initialValues,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={24}>
              <Form.Item
                label="Nama Lokasi"
                name="nama_lokasi"
                rules={[
                  {
                    required: true,
                    message: 'Please input Nama Lokasi!',
                  },
                ]}
              >
                <Input
                  size="small"
                  style={{ paddingTop: 6, paddingBottom: 6 }}
                  placeholder="Masukan nama lokasi"
                  onChange={(e) => {
                    if (!update) {
                      form.setFieldsValue({
                        role_type: e.target.value ? e.target.value.replace(/\s/g, '_').toLowerCase() : '',
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Kode Lokasi"
                name="kode_lokasi"
                rules={[
                  {
                    required: true,
                    message: 'Please input kode Lokasi!',
                  },
                ]}
              >
                <Input
                  size="small"
                  style={{ paddingTop: 6, paddingBottom: 6 }}
                  placeholder="Masukan kode lokasi"
                  onChange={(e) => {
                    if (!update) {
                      form.setFieldsValue({
                        role_type: e.target.value ? e.target.value.replace(/\s/g, '_').toLowerCase() : '',
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="Lokasi Induk" name="parent_id">
                <Select allowClear placeholder="Pilih Lokasi Induk" size="medium">
                  {parentMenu.map((item) => (
                    <Select.Option key={item.id} value={`${item.id}`}>
                      {item.nama_lokasi}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;
