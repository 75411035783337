import { createSlice } from '@reduxjs/toolkit';
import staticData from '../../config/config';

const initialState = {
  rtlData: staticData.rtl,
  topMenu: staticData.topMenu,
  mode: staticData.mainTemplate,
  loading: false,
  rtlLoading: false,
  menuLoading: false,
  mainContentLoading: false,
  error: null,
};

const themeReducer = createSlice({
  initialState,
  name: 'theme',
  reducers: {
    setMenuLoading: (state, action) => {
      state.menuLoading = action.payload;
    },
    setMainContent: (state, action) => {
      state.mainContentLoading = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMenuLoading, setMainContent, setLoading } = themeReducer.actions;

export default themeReducer.reducer;
