import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { Modal } from '../../../../../components/modals/antd-modals';
const ModalForm = ({ initialValues = {}, update = false, onSubmit }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    onSubmit({ ...initialValues, ...value }, () => setIsModalOpen(false));
  };

  return (
    <>
      {update ? (
        <span onClick={() => showModal()} className="cursor-pointer">
          Edit
        </span>
      ) : (
        <Button onClick={() => showModal()} type="primary" className="bg-blue-600">
          <PlusOutlined color="#fff" />
          <span className="ml-2">Role Baru</span>
        </Button>
      )}
      <Modal
        title={update ? 'Edit Role' : 'Tambah Role Baru'}
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{
            ...initialValues,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={24}>
              <Form.Item label="Role Name" name="role_name">
                <Input
                  size="small"
                  style={{ paddingTop: 6, paddingBottom: 6 }}
                  onChange={(e) => {
                    if (!update) {
                      form.setFieldsValue({
                        role_type: e.target.value ? e.target.value.replace(/\s/g, '_').toLowerCase() : '',
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Role Type"
                name="role_type"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Role Type!',
                  },
                ]}
              >
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} disabled={update} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;
