/* eslint-disable react/jsx-no-bind */
import UilEllipsisH from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { Button, Col, Dropdown, Row, Table, message } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../../components/atoms/ConfirmModal';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../../components/page-headers/page-headers';
import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useGetRoleListQuery,
  useUpdateRoleMutation,
} from '../../../../redux/service/sitesService';
import ModalForm from './components/ModalForm';
import { dataTableColumn } from './config';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';

const PageRoutes = [
  {
    path: 'index',
    breadcrumbName: 'Site Management',
  },
  {
    path: 'first',
    breadcrumbName: 'Role',
  },
];
function RolePage() {
  const dispatch = useDispatch();

  const roleResult = useGetRoleListQuery();
  const [updateRole, updateRoleResult] = useUpdateRoleMutation();
  const [createRole, createRoleResult] = useCreateRoleMutation();
  const [deleteRole, deleteRoleResult] = useDeleteRoleMutation();

  const roles = roleResult?.data?.data || [];
  const handleCreateRole = (e, callback) => {
    createRole(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.data?.message);
      }
      callback();
      message.success(data?.message);
      return roleResult.refetch();
    });
  };

  const handleUpdateRole = (e, callback) => {
    updateRole(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.message);
      }
      callback();
      message.success(data?.message);
      return roleResult.refetch();
    });
  };

  const handleDeleteRole = (record) => {
    deleteRole({ uuid: record.uuid }).then(({ data, error }) => {
      if (data) {
        message.success('Data Berhasil Dihapus');
        return roleResult.refetch();
      }

      message.error('Data Gagal Dihapus');
    });
  };

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Sites"
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={15}>
          <Col xs={24}>
            <div>
              <Cards
                title="List Role"
                isbutton={
                  <ModalForm
                    onSubmit={(e, callback) => handleCreateRole(e, callback)}
                    loading={createRoleResult.isLoading}
                  />
                }
              >
                <Table
                  pagination={false}
                  dataSource={roles}
                  loading={roleResult.isLoading}
                  columns={[
                    ...dataTableColumn,
                    {
                      title: 'Actions',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      render: (text, record, index) => {
                        return (
                          <div>
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: '1',
                                    icon: <UilEdit />,
                                    label: (
                                      <ModalForm
                                        update
                                        initialValues={{ ...record, _method: 'PUT' }}
                                        onSubmit={(e, callback) => handleUpdateRole(e, callback)}
                                        loading={updateRoleResult.isLoading}
                                      />
                                    ),
                                  },
                                  {
                                    key: '2',
                                    icon: <UilTrashAlt />,
                                    label: (
                                      <ConfirmModal handleClick={() => handleDeleteRole(record)}>
                                        <span>Delete</span>
                                      </ConfirmModal>
                                    ),
                                  },
                                ],
                              }}
                              placement="bottomRight"
                            >
                              <Button>
                                <UilEllipsisH />
                              </Button>
                            </Dropdown>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </Cards>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default RolePage;
