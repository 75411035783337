import { theme } from './theme/themeVariables';

const config = {
  topMenu: false,
  rtl: false,
  mainTemplate: 'lightMode',
  theme,
  components: {
    Menu: {
      colorItemBgHover: "#F3F9FE",
      colorItemBgSelected: "#F3F9FE",
      colorItemTextSelected: "#26BEF0",
    },
  },
};

export default config;
