import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useGetBarangQuery, useGetJadwalQuery, useGetKategoriBarangQuery } from '../../redux/service/masterService';
import { useGetUserQuery } from '../../redux/service/userService';
import { dataTableColumn } from '../pages/jadwal-pengecekan/config';
import { DataService } from '../../config/dataService/dataService';
import { useNavigate } from 'react-router-dom';
import { PieChartOutlined, ProjectOutlined, ApartmentOutlined } from "@ant-design/icons"

function Dashboard() {
  const navigation = useNavigate()

  const [dataDashboard, setDataDashboard] = useState({
    "total_barang_rusak_berat" : 0,
    "total_barang_rusak_ringan": 0,
    "total_barang"      : 0,
    "total_penguna"     : 0,
    "total_kategori"    : 0
})
  
  useEffect(() => {
    getDashboardData()
  }, [])

  const getDashboardData = async () => {
    try {
      const dashboardData = await DataService.get("/dashboard/data")
      if(dashboardData.status === 200) {
        setDataDashboard(dashboardData.data.data)
      }

    } catch (error) {
      console.log("failed get dashboard => ", error.message);
    }
  }

  const PageRoutes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
  ];

  const { data: jadwalResult, isLoading: loadingJadwal } = useGetJadwalQuery();

  const detailDashboard = (link) => {
    navigation(link)
  }

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Dashboard"
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={25}>
          <Col md={12} xs={24} onClick={() => detailDashboard("../barang")} className='cursor-pointer'>
            <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
              <div className="p-[25px] text-center">
                <div className="flex justify-center items-center">
                  <div className="flex rounded-lg p-2 justify-center items-center" style={{ background:'#F5FAFF', height:50, width:50 }}>
                    <PieChartOutlined style={{ fontSize: 20, color:"#437EF7" }}/>
                  </div>
                </div>
                <h1 className="mb-0 text-lg text-dark dark:text-white60 mt-4">Total Barang</h1>
                <h1 className="mb-0 text-3xl font-bold text-dark dark:text-white60">{dataDashboard.total_barang}</h1>
              </div>
            </div>
          </Col>
          <Col md={12} xs={24} onClick={() => detailDashboard("../pengguna")} className='cursor-pointer'>
            <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
              <div className="p-[25px] text-center">
                <div className="flex justify-center items-center">
                  <div className="flex rounded-lg p-2 justify-center items-center" style={{ background:'#F5FAFF', height:50, width:50 }}>
                    <ProjectOutlined style={{ fontSize: 20, color:"#437EF7" }}/>
                  </div>
                </div>
                <h1 className="mb-0 text-lg text-dark dark:text-white60 mt-4">Total Pengguna</h1>
                <h1 className="mb-0 text-3xl font-bold text-dark dark:text-white60">{dataDashboard.total_penguna}</h1>
              </div>
            </div>
          </Col>
          <Col md={12} xs={24} onClick={() => detailDashboard("../master/kategori-barang")} className='cursor-pointer'>
            <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
              <div className="p-[25px] text-center">
                <div className="flex justify-center items-center">
                  <div className="flex rounded-lg p-2 justify-center items-center" style={{ background:'#F5FAFF', height:50, width:50 }}>
                    <ApartmentOutlined style={{ fontSize: 20, color:"#437EF7" }}/>
                  </div>
                </div>
                <h1 className="mb-0 text-lg text-dark dark:text-white60 mt-4">Total Kategori</h1>
                <h1 className="mb-0 text-3xl font-bold text-dark dark:text-white60">{dataDashboard.total_kategori}</h1>
              </div>
            </div>
          </Col>
          <Col md={12} xs={24} className='cursor-pointer'>
            <div className="flex flex-row w-full">
              <div className="flex w-full mr-2 select-none cursor-pointer" onClick={() => detailDashboard("../barang?kondisi=rusak ringan")}>
                <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative w-full">
                  <div className="p-[25px] text-center">
                    <div className="flex justify-center items-center">
                      <div className="flex rounded-lg p-2 justify-center items-center" style={{ background:'#F5FAFF', height:50, width:50 }}>
                        <ApartmentOutlined style={{ fontSize: 20, color:"#437EF7" }}/>
                      </div>
                    </div>
                    <h1 className="mb-0 text-lg text-dark dark:text-white60 mt-4">Total rusak ringan</h1>
                    <h1 className="mb-0 text-3xl font-bold text-dark dark:text-white60">{dataDashboard.total_barang_rusak_ringan ? dataDashboard.total_barang_rusak_ringan : 0 }</h1>
                  </div>
                </div>
              </div>
              <div className="flex w-full ml-2 select-none cursor-pointer" onClick={() => detailDashboard("../barang?kondisi=rusak berat")}>
                <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative w-full">
                  <div className="p-[25px] text-center">
                    <div className="flex justify-center items-center">
                      <div className="flex rounded-lg p-2 justify-center items-center" style={{ background:'#F5FAFF', height:50, width:50 }}>
                        <ApartmentOutlined style={{ fontSize: 20, color:"#437EF7" }}/>
                      </div>
                    </div>
                    <h1 className="mb-0 text-lg text-dark dark:text-white60 mt-4">Total rusak berat</h1>
                    <h1 className="mb-0 text-3xl font-bold text-dark dark:text-white60">{dataDashboard.total_barang_rusak_berat ? dataDashboard.total_barang_rusak_berat : 0}</h1>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24}>
            <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
              <Table
                pagination={false}
                dataSource={jadwalResult?.data || []}
                loading={loadingJadwal}
                columns={dataTableColumn}
              />
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default Dashboard;
