const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Barang',
    dataIndex: 'nama_barang',
    key: 'nama_barang',
  },
  {
    title: 'Nama Penukar',
    dataIndex: 'alasan_pemusnahan',
    key: 'alasan_pemusnahan',
  },
  {
    title: 'Ditukar dengan',
    dataIndex: 'barang_yang_ditukar',
    key: 'barang_yang_ditukar',
  },
  {
    title: 'Tanggal Tukar',
    dataIndex: 'tanggal_tukar',
    key: 'tanggal_tukar',
  },
];

export { dataTableColumn };
