import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalMenuOpen: false,
  isModalRoleMenuOpen: false,
};

const sitesReducer = createSlice({
  initialState,
  name: 'sites',
  reducers: {
    setModalMenuOpen: (state, action) => {
      state.isModalMenuOpen = action.payload;
    },
    setModalRoleMenuOpen: (state, action) => {
      state.isModalRoleMenuOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setModalMenuOpen, setModalRoleMenuOpen } = sitesReducer.actions;

export default sitesReducer.reducer;
