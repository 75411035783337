const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Barang',
    dataIndex: 'nama_barang',
    key: 'nama_barang',
  },
  {
    title: 'Nama Pembeli',
    dataIndex: 'nama_pembeli',
    key: 'nama_pembeli',
  },
  {
    title: 'Harga Jual',
    dataIndex: 'harga_jual',
    key: 'harga_jual',
  },
  {
    title: 'Tanggal Jual',
    dataIndex: 'tanggal_jual',
    key: 'tanggal_jual',
  },
];

export { dataTableColumn };
