import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

export const sitesService = createApi({
  reducerPath: 'sitesService',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', `Bearer ${getItem('access_token')}`);
      return headers;
    },
  }),
  tagTypes: ['menuList', 'roleList'],
  endpoints: (builder) => ({
    // menu
    getMenuList: builder.mutation({
      query: (body) => ({
        url: `/sites/menu/list`,
        method: 'POST',
        body: body,
      }),
      providesTags: ['menuList'],
    }),
    createMenu: builder.mutation({
      query: (body) => ({
        url: `/sites/menu`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['menuList'],
    }),
    updateMenu: builder.mutation({
      query: (body) => ({
        url: `/sites/menu/${body.uuid}`,
        method: 'PUT',
        body: { _method: 'PUT', ...body },
      }),
      invalidatesTags: ['menuList'],
    }),
    updateMenuRole: builder.mutation({
      query: (body) => ({
        url: `/sites/menu/role/${body.uuid}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['menuList'],
    }),
    deleteMenu: builder.mutation({
      query: (body) => ({
        url: `/sites/menu/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['menuList'],
    }),

    // role
    getRoleList: builder.query({
      query: () => `/sites/role`,
      providesTags: ['roleList'],
    }),
    createRole: builder.mutation({
      query: (body) => ({
        url: `/sites/role`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['roleList'],
    }),
    updateRole: builder.mutation({
      query: (body) => ({
        url: `/sites/role/${body.uuid}`,
        method: 'PUT',
        body: { _method: 'PUT', ...body },
      }),
      invalidatesTags: ['roleList'],
    }),
    deleteRole: builder.mutation({
      query: (body) => ({
        url: `/sites/role/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['roleList'],
    }),
  }),
});

export const {
  // menu
  useGetMenuListMutation,
  useCreateMenuMutation,
  useUpdateMenuMutation,
  useUpdateMenuRoleMutation,
  useDeleteMenuMutation,
  // role
  useGetRoleListQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = sitesService;
