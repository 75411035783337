/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Timeline, Carousel, Modal, Button, Row, Col } from 'antd';
import { RightOutlined } from "@ant-design/icons"
import moment from 'moment';
import { LeftOutlined } from "@ant-design/icons"
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { useGetBarangDetailQuery } from '../../../redux/service/masterService';
import { formatNumber } from '../../../utility/utility';
import { DataService } from '../../../config/dataService/dataService';

const PageRoutes = [
  {
    path: '/admin/barang',
    breadcrumbName: 'Barang',
  },
  {
    path: '#',
    breadcrumbName: 'Detail Barang',
  },
];

function BarangDetailPage() {
  const location = useLocation()
  const carouserRef = useRef()
  const { barang_id } = useParams();

  const listBarang = useGetBarangDetailQuery(barang_id);
  const barangDataOld = listBarang?.data?.data || {};
  const [fullImages, setFullImages] = useState("") 
  const [showImages, setShowImages] = useState(false)

  const [loadingHistoryUpdate, setLodaingHistoryUpdate] = useState(false)
  const [listHistoryUpdate, setListHistoryUpdate] = useState([])
  const [listImages, setListImages] = useState([])

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0]
    
    if(listBarang.data !== undefined) {
      getHistoryUpdate(listBarang.data.data.id)
    }

    if(fullImages !== "") {
      body.style.overflow = "hidden"

    } else {
      body.style.overflow = "auto"
    }

    if(barangDataOld?.foto_barang) {
      setListImages(barangDataOld?.foto_barang)
    }

  }, [listBarang, fullImages, location])

  function capitalizeFirstChar(inputString) {
      if (!inputString) {
          return "";
      }

      // Jika string hanya satu karakter, kembalikan string tanpa perubahan
      if (inputString.length === 1) {
          return inputString.toUpperCase();
      }

      // Capitalize karakter pertama
      let capitalizedString = inputString.charAt(0).toUpperCase() + inputString.slice(1);
      return capitalizedString;
  }

  const getHistoryUpdate = async (id) => {
    setLodaingHistoryUpdate(true)

    try {
      const history = await DataService.get(`/barang/history-update/${id}`)
      if(history.status === 200) {
        let dataUpdate = []

        if(history.data.data.length > 0) {
          for(const i in history.data.data) {
            dataUpdate.push({
              "children" : history.data.data[i].keterangan+" oleh "+ history.data.data[i].user_updated.name + " pada " + moment(history.data.data[i].created_at).format("HH:mm DD/MM/YYYY"),
              "color": parseInt(i) === (history.data.data.length-1) ? 'red' : 'green',
            })
          }
        }

        setListHistoryUpdate(dataUpdate)
      }
    } catch (error) {
      console.log("Filed load history ", error.message);
    }

    setLodaingHistoryUpdate(false)
  }

  const handleNext = () =>  carouserRef.current.next();
  const handlePrev = () => carouserRef.current.prev();

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title={barangDataOld?.nama_barang}
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Cards title="Informasi Barang" edit={`/admin/barang/form/${barang_id}`}>
          <Row>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Nama Barang</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {barangDataOld?.nama_barang}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Kode Resmi</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {barangDataOld?.kode_barang_resmi === null ? "-" : barangDataOld?.kode_barang_resmi}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Kode Barang</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {barangDataOld?.kode_barang}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Kategori Barang</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {barangDataOld?.nama_kategori}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Kondisi</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {capitalizeFirstChar(barangDataOld?.kondisi)}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Nama Lokasi</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {barangDataOld?.lokasi?.nama_lokasi}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Tahun Pembelian</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {barangDataOld?.tahun_pembelian}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Nilai Perolehan</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {formatNumber(barangDataOld?.nilai_perolehan)}
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Karyawan</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {(barangDataOld.karyawan !== undefined && barangDataOld.karyawan !== null) ? barangDataOld.karyawan.nama : "-"}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Cards>

        <Cards title="Penyusutan" className={'mt-4'}>
          <Row>
            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Metode Penyusutan</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {barangDataOld?.nama_penyusutan}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Masa Manfaat</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {barangDataOld?.masa_manfaat}
                  </span>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} className='p-1'>
              <div className='flex w-full flex-col my-2'>
                <div className='flex w-full'>
                  <span className='font-medium text-gray-400 text-xs'>Nilai Per Tahun</span>
                </div>
                <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                  <span className='text-md'>
                    {formatNumber(barangDataOld?.nilai_pertahun)}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Cards>

        <Cards title="Foto Barang" className={'mt-4'}>
          <div className="flex space-x-2 flex-wrap" onClick={() => setShowImages(true)}>
            {barangDataOld?.foto_barang?.map((item) => (
              <img key={item.id} src={item.foto_barang} className="w-20 h-20 cursor-pointer select-none" />
            ))}
          </div>
        </Cards>

        <Cards title="Riwayat update data" className={'mt-4'}>
          {
            loadingHistoryUpdate ?
              <div className='flex flex-col w-full'>
                <div className='w-full flex items-center my-2'>
                  <div className='rounded-lg max-w-[200px] bg-gray-200 animate-pulse h-[15px] w-[15px] mr-2'></div>
                  <div className='w-full rounded-lg max-w-[200px] bg-gray-200 animate-pulse h-[15px]'></div>
                </div>
                <div className='w-full flex items-center my-2'>
                  <div className='rounded-lg max-w-[200px] bg-gray-200 animate-pulse h-[15px] w-[15px] mr-2'></div>
                  <div className='w-full rounded-lg max-w-[200px] bg-gray-200 animate-pulse h-[15px]'></div>
                </div>
                <div className='w-full flex items-center my-2'>
                  <div className='rounded-lg max-w-[200px] bg-gray-200 animate-pulse h-[15px] w-[15px] mr-2'></div>
                  <div className='w-full rounded-lg max-w-[200px] bg-gray-200 animate-pulse h-[15px]'></div>
                </div>
              </div>
            
            :
              listHistoryUpdate.length > 0 ?
                <Timeline
                  items={listHistoryUpdate}
                />
              :
                <div className='w-full flex justify-center'>
                  <p className='text-gray-400 italic'>Belum pernah di update</p>
                </div>
          }
        </Cards>
      </main>

      {/* modals View images */}
      <Modal
        title="Lihat Gambar"
        open={showImages}
        onCancel={() => setShowImages(false)}
        footer={null}
        width={1000}
        bodyStyle={{ marginInline: -20 }}
      >
        <div className='w-full h-full relative'>
          <Carousel ref={carouserRef}>
            {listImages.map((item, i) => (
              <div key={i} className='flex w-full items-center justify-center'>
                <img key={item.id} src={item.foto_barang} className="h-[500px] object-contains" />
              </div>
            ))}
          </Carousel>

          <Button 
            onClick={() => handlePrev()}
            className='bg-black/50 rounded-full w-[40px] h-[40px] flex items-center justify-center absolute left-5 top-1/2 transform -translate-y-1/2'>
            <LeftOutlined style={{ color: "#fff", fontSize:20 }}/>
          </Button>

          <Button 
            onClick={() => handleNext()}
            className='bg-black/50 rounded-full w-[40px] h-[40px] flex items-center justify-center absolute right-5 top-1/2 transform -translate-y-1/2'>
            <RightOutlined style={{ color: "#fff", fontSize:20 }}/>
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default BarangDetailPage;
