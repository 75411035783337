import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

export const userService = createApi({
  reducerPath: 'userService',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', `Bearer ${getItem('access_token')}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMenu: builder.query({
      query: () => '/user/menu',
    }),

    // user
    getUserList: builder.mutation({
      query: (body) => ({
        url: `/user/contact/list`,
        method: 'POST',
        body: body,
      }),
    }),
    getUser: builder.query({
      query: () => '/user/contact',
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: `/user/contact`,
        method: 'POST',
        body,
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `/user/contact/${body.uuid}`,
        method: 'PUT',
        body: { _method: 'PUT', ...body },
      }),
    }),
    deleteUser: builder.mutation({
      query: (body) => ({
        url: `/user/contact/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
    }),
  }),
});

export const {
  useGetMenuQuery,
  useGetUserQuery,
  useGetUserListMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userService;
