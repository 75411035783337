import { createSlice } from '@reduxjs/toolkit';
import { getItem } from '../../utility/localStorageControl';

const initialState = {
  login: getItem('logedIn'),
  token: getItem('access_token'),
  user: getItem('user'),
  menus: [],
  error: null,
  loading: false,
};

const authReducer = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setLogin: (state, action) => {
      state.login = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setMenus: (state, action) => {
      state.menus = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setError, setLogin, setToken, setLoading, setUser, setMenus } = authReducer.actions;

export default authReducer.reducer;
