const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Barang',
    dataIndex: 'nama_barang',
    key: 'nama_barang',
  },
  {
    title: 'Alasan Pemusnahan',
    dataIndex: 'alasan_pemusnahan',
    key: 'alasan_pemusnahan',
  },
  {
    title: 'Tanggal Pemusnahan',
    dataIndex: 'tanggal_pemusnahan',
    key: 'tanggal_pemusnahan',
  },
  {
    title: 'Dimusnahkan Oleh',
    dataIndex: 'user_created_name',
    key: 'user_created_name',
  },
];

export { dataTableColumn };
