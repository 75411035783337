import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../container/dashboard';
import withAdminLayout from '../../layout/withAdminLayout';
import Pages from './pages';
import SitesRoute from './sites';
import MasterRoute from './master';
import BarangPage from '../../container/pages/barang';
import BarangFormPage from '../../container/pages/barang/barangForm';
import UserPage from '../../container/pages/user';
import JadwalPage from '../../container/pages/jadwal-pengecekan';
import BarangDetailPage from '../../container/pages/barang/barangDetail';
import PemindahanRoute from './pemindahan';
import KaryawanPage from '../../container/pages/karyawan';
import DetailEmploye from '../../container/pages/karyawan/detail';
import LokasiPage from '../../container/pages/master/lokasi';
import DetailLokasi from '../../container/pages/master/lokasi/detail';
import CheckBarang from '../../container/pages/master/lokasi/cek-barang';
import BarangUpdate from '../../container/pages/master/lokasi/cek-barang/update-barang';

const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="sites/*" element={<SitesRoute />} />
        <Route path="master/*" element={<MasterRoute />} />
        <Route path="pemindahan/*" element={<PemindahanRoute />} />
        <Route path="pengguna" element={<UserPage />} />
        <Route path="jadwal-pengecekan" element={<JadwalPage />} />
        <Route path="barang" element={<BarangPage />} />
        <Route path="barang/form" element={<BarangFormPage />} />
        <Route path="barang/form/:barang_id" element={<BarangFormPage />} />
        <Route path="barang/detail/:barang_id" element={<BarangDetailPage />} />
        <Route path="karyawan" element={<KaryawanPage />} />
        <Route path="karyawan/detail/:id" element={<DetailEmploye />} />
        <Route path="lokasi" element={<LokasiPage />} />
        <Route path="lokasi/detail/:id" element={<DetailLokasi />} />
        <Route path="lokasi/detail/check-barang/:id" element={<CheckBarang />} />
        <Route path="lokasi/detail/check-barang/perbarui-barang/:barang_id" element={<BarangUpdate />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
