import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Modal } from '../../../../components/modals/antd-modals';
import { useGetRoleListQuery } from '../../../../redux/service/sitesService';
const ModalForm = ({ initialValues = {}, update = false, onSubmit }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const roleResult = useGetRoleListQuery();

  const roles = roleResult?.data?.data || [];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (value) => {
    onSubmit({ ...initialValues, ...value }, () => setIsModalOpen(false));
  };

  return (
    <>
      {update ? (
        <span onClick={() => showModal()} className="cursor-pointer">
          <div className="flex items-center justify-center">
              <EditOutlined style={{ fontSize:15, color: "#fff" }}/>
              <span className='text-xs text-white ml-1'>
                Edit
              </span>
          </div>
        </span>
      ) : (
        <Button onClick={() => showModal()} type="primary" className="bg-blue-600 flex items-center">
          <PlusOutlined color="#fff" />
          <span className="ml-2">Pengguna</span>
        </Button>
      )}
      <Modal
        title={update ? 'Edit Pengguna' : 'Tambah Pengguna'}
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{
            ...initialValues,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row gutter={15}>
            <Col xs={24}>
              <Form.Item label="Nama Pengguna" name="name">
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <Input size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
              <Form.Item label="Kata Sandi Pengguna" name="password">
                <Input.Password size="small" style={{ paddingTop: 6, paddingBottom: 6 }} />
              </Form.Item>
              <Form.Item
                label="Role"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Role!',
                  },
                ]}
              >
                <Select allowClear className="w-full mb-2" placeholder="Pilih Role">
                  {roles.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.role_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Please input status!',
                  },
                ]}
              >
                <Select allowClear className="w-full mb-2" placeholder="Pilih Status">
                  <Select.Option value={'1'}>Aktif</Select.Option>
                  <Select.Option value={'0'}>Tidak Aktif</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalForm;
