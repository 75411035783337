import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import RolePage from '../../container/pages/sites/role';

const NotFound = lazy(() => import('../../container/pages/404'));
const Maintenance = lazy(() => import('../../container/pages/Maintenance'));
const MenuPage = lazy(() => import('../../container/pages/sites/menu'));

function SitesRoute() {
  return (
    <Routes>
      <Route path="menu" element={<MenuPage />} />
      <Route path="role" element={<RolePage />} />
      <Route path="maintenance" element={<Maintenance />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default SitesRoute;
