import { Col, Row, Table, message } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteOutlined } from "@ant-design/icons"
import ConfirmModal from '../../../components/atoms/ConfirmModal';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';

import ModalForm from './components/ModalForm';
import { dataTableColumn } from './config';
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUserListMutation,
  useUpdateUserMutation,
} from '../../../redux/service/userService';

const PageRoutes = [
  {
    path: 'pengguna',
    breadcrumbName: 'Pengguna',
  },
];
function UserPage() {
  const dispatch = useDispatch();

  const [listUser, userResult] = useGetUserListMutation();
  const [updateUser, updateUserResult] = useUpdateUserMutation();
  const [createUser, createUserResult] = useCreateUserMutation();
  const [deleteUser, deleteUserResult] = useDeleteUserMutation();

  useEffect(() => {
    listUser();
  }, []);

  const User = userResult?.data?.data?.data || [];
  const handleCreateUser = (e, callback) => {
    createUser(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.data?.message);
      }
      callback();
      message.success(data?.message);
      return listUser();
    });
  };

  const handleUpdateUser = (e, callback) => {
    updateUser(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.message);
      }
      callback();
      message.success(data?.message);
      return listUser();
    });
  };

  const handleDeleteUser = (record) => {
    deleteUser({ uuid: record.uuid }).then(({ data, error }) => {
      if (data) {
        message.success('Data Berhasil Dihapus');
        return listUser();
      }

      message.error('Data Gagal Dihapus');
    });
  };

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Kelola Penguna"
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={15}>
          <Col xs={24}>
            <div>
              <Cards
                title="List Pengguna"
                isbutton={
                  <ModalForm
                    onSubmit={(e, callback) => handleCreateUser(e, callback)}
                    loading={createUserResult.isLoading}
                  />
                }
              >
                <Table
                  pagination={false}
                  dataSource={User}
                  loading={userResult.isLoading}
                  columns={[
                    ...dataTableColumn,
                    {
                      title: 'Actions',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      render: (text, record, index) => {
                        return (
                          <div className='flex justify-center items-center'>

                            <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ModalForm
                                update
                                initialValues={{ ...record, role_id: record?.role?.id, _method: 'PUT' }}
                                onSubmit={(e, callback) => handleUpdateUser(e, callback)}
                                loading={updateUserResult.isLoading}
                              />
                            </div>

                            <div className='flex bg-red-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ConfirmModal handleClick={() => handleDeleteUser(record)}>
                                <div className="flex items-center justify-center">
                                  <DeleteOutlined style={{ fontSize:15, color: "#fff" }}/>
                                  <span className="ml-1 text-xs text-white">Delete</span>
                                </div>
                              </ConfirmModal>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </Cards>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default UserPage;
