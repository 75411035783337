import { Card, Button, Row, Col, Table, Pagination, message } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { DataService } from "../../../../../config/dataService/dataService"
import { useParams } from "react-router-dom"
import { QRCode } from "react-qrcode-logo"
import moment from "moment"
import { formatNumber } from "../../../../../utility/utility"

const DetailLokasi = () => {
    const { id } = useParams()
    const [detailLokasi, setDetailLokasi] = useState({})
    const [loading, setLoading]           = useState(false)
    const [dataBarang, setDataBarang]     = useState([])
    const [dataHistory, setDataHistory]   = useState([])
    const [loadingBarang, setLoadingBarang] = useState(false)
    const [pagesBarangData, setpagesBarangData]  = useState({
        current_page : 1,
        total_page   : 0
    })

    const dataTableHistory = [
        {
            title: 'No.',
            dataIndex: 'nomor',
            key: 'nomor',
            render: (text, record, index) => ((pagesBarangData.current_page !== undefined ? pagesBarangData.current_page : 1) - 1) * 10 + index + 1
        },
        {
            title: 'Tanggal',
            render: (_, text) => (
                moment(text.tanggal).format("DD/MM/YYYY")
            )
        },
        {
            title: 'Jumlah Barang',
            dataIndex: 'total_barang',
            key: 'total_barang',
            align : 'center'
        },
        {
            title: 'Barang Baik',
            dataIndex: 'total_barang_baik',
            key: 'total_barang_baik',
            align : 'center'
        },
        {
            title: 'Barang Rusak Ringan',
            dataIndex: 'total_barang_rusak_ringan',
            key: 'total_barang_rusak_ringan',
            align : 'center'
        },
        {
            title: 'Barang Rusak Berat',
            dataIndex: 'total_barang_rusak_berat',
            key: 'total_barang_rusak_berat',
            align : 'center'
        }
    ];

    const dataTableHistoryBarangColumn = [
        {
            title: 'Nama Barang',
            render : (_, text) => (
                <span>{text.barang.nama_barang}</span>
            )
        },
        {
            title: 'Kode Barang',
            render : (_, text) => (
                <span>{text.barang.kode_barang}</span>
            )
        },
        {
            title: 'Kondisi',
            render : (_, text) => (
                <span className="uppercase">{text.kondisi}</span>
            )
        }
    ];

    const dataTableColumn = [
        {
            title: 'No.',
            dataIndex: 'nomor',
            key: 'nomor',
            render: (text, record, index) => ((pagesBarangData.current_page !== undefined ? pagesBarangData.current_page : 1) - 1) * 10 + index + 1
        },
        {
            title: 'Nama Barang',
            dataIndex: 'nama_barang',
            key: 'nama_barang',
        },
        {
            title: 'Kode Barang',
            dataIndex: 'kode_barang',
            key: 'kode_barang',
        },
        {
            title: 'Tahun Pembelian',
            dataIndex: 'tahun_pembelian',
            key: 'tahun_pembelian',
        },
        {
            title: 'Nilai Perolehan',
            dataIndex: 'nilai_perolehan',
            key: 'nilai_perolehan',
            render : (_, text) => (
                <span>{formatNumber(text.nilai_perolehan)}</span>
            )
        },
        {
            title: 'Kondisi',
            dataIndex: 'kondisi',
            key: 'kondisi',
            render : (_, text) => (
                <span className="uppercase">{text.kondisi}</span>
            )
        },
        {
            title: 'Tanggal Cek',
            render: (_, text) => (
                <span>
                    {text.check_date !== null ? moment(text.check_date).format("DD/MM/YYYY") : "-"}
                </span>
            )
        },
    ];

    const history = async (lokasiId="") => {
        setLoadingBarang(true)
        try {
            const data = await DataService.get(`/history?lokasi_id=${lokasiId}`)
            const result = data.data.data.data

            let historyWithChild = []
            for(let i=0; i<result.length; i++) {
                const getDetailHistory = await DataService.get(`/detail-history?lokasi_id=${lokasiId}&tanggal=${result[i].tanggal}`)
                historyWithChild.push({
                    ...result[i],
                    child : getDetailHistory.data.data
                })
            }
            setDataHistory(historyWithChild)
            setpagesBarangData(data.data.data.total)

        } catch (error) {
            message.error("Gagal mengambil data barang !")

        } finally {
            setLoadingBarang(false)
        }
    }

    const barang = async (lokasiId="") => {
        setLoadingBarang(true)
        try {
            const data = await DataService.get(`barang?lokasi_id=${lokasiId}`)
            setDataBarang(data.data.data.data)
            setpagesBarangData(data.data.data.total)

        } catch (error) {
            message.error("Gagal mengambil data barang !")

        } finally {
            setLoadingBarang(false)
        }
    }

    const getLokasiDetail = async () => {
        setLoading(true)
        try {
            const lokasi = await DataService.get(`/lokasi/${id}`)
            const result = lokasi.data.data
            setDetailLokasi(result)
            barang(result.id)
            history(result.id)

        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }

    const proccessFilterDataBarang = (page=1) => {

    }

    const proccessFilterDataHistory = (page=1) => {
        
    }

    useEffect(() => {
        getLokasiDetail()
    }, [])

    return (
        <div className="flex min-h-screen w-full px-4 flex-col">
            <Card title="Detail Lokasi" className="mt-4 mb-4 w-full">
            {
                loading ?
                    <div className="flex w-full justify-center py-2">
                        <span>Loading ...</span>
                    </div>
                :
                    Object.keys(detailLokasi).length > 0 &&
                    <>
                        <Row>
                            <Col xs={24} className='p-1'>
                                <div className='flex w-full flex-col my-2 justify-center items-center mb-4'>
                                    <QRCode value={detailLokasi.kode_lokasi} size={100}/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={12} className='p-1'>
                                <div className='flex w-full flex-col my-2'>
                                    <div className='flex w-full'>
                                        <span className='font-medium text-gray-400 text-xs'>Nama</span>
                                    </div>
                                    <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                                        <span className='text-md'>
                                            {detailLokasi.nama_lokasi}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} className='p-1'>
                                <div className='flex w-full flex-col my-2'>
                                    <div className='flex w-full'>
                                        <span className='font-medium text-gray-400 text-xs'>Kode Lokasi</span>
                                    </div>
                                    <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                                        <span className='text-md'>
                                            {detailLokasi.kode_lokasi}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
            }
            </Card>

            <Card title="Riwayat Pengecekan" className="mt-4 mb-4 w-full">
                <div className="overflow-x-auto max-w-[100%]">
                    <Table
                        pagination={false}
                        dataSource={dataHistory}
                        loading={loadingBarang}
                        rowKey='id'
                        columns={dataTableHistory}
                        expandable={{
                            expandedRowRender: (record) => (
                                <Table
                                    columns={dataTableHistoryBarangColumn}
                                    dataSource={record.child}
                                    pagination={false}
                                />
                            )
                        }}
                    />

                    <Pagination
                        defaultCurrent={1}
                        current={pagesBarangData?.current_page}
                        total={pagesBarangData?.total}
                        className="mt-4 mx-auto"
                        onChange={(e) => proccessFilterDataHistory(e)}
                    />
                </div>
            </Card>

            <Card title="Daftar Barang" className="mt-4 mb-4 w-full"  extra={<Button icon={<PlusOutlined/>} href={`/admin/lokasi/detail/check-barang/${id}`}>Mulai Pengecekan</Button>}>
                <div className="overflow-x-auto max-w-[100%]">
                    <Table
                        pagination={false}
                        dataSource={dataBarang}
                        loading={loadingBarang}
                        rowKey='id'
                        columns={dataTableColumn}
                    />

                    <Pagination
                        defaultCurrent={1}
                        current={pagesBarangData?.current_page}
                        total={pagesBarangData?.total}
                        className="mt-4 mx-auto"
                        onChange={(e) => proccessFilterDataBarang(e)}
                    />
                </div>
            </Card>
        </div>
    )
}

export default DetailLokasi