import { Col, Row, Table, message } from 'antd';
import React, { useEffect } from 'react';
import { DeleteOutlined } from "@ant-design/icons"
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../components/atoms/ConfirmModal';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import {
  useCreateJadwalMutation,
  useDeleteJadwalMutation,
  useGetBarangQuery,
  useGetJadwalListMutation,
  useUpdateJadwalMutation,
} from '../../../redux/service/masterService';
import ModalForm from './components/ModalForm';
import { dataTableColumn } from './config';

const PageRoutes = [
  {
    path: 'index',
    breadcrumbName: 'Master',
  },
  {
    path: 'jadwal-pengecekan',
    breadcrumbName: 'Jadwal Pengecekan',
  },
];
function JadwalPage() {
  const dispatch = useDispatch();

  const { data: barangData, isLoading: barangLoading } = useGetBarangQuery();
  const [listJadwal, jadwalResult] = useGetJadwalListMutation();
  const [updateJadwal, updateJadwalResult] = useUpdateJadwalMutation();
  const [createJadwal, createJadwalResult] = useCreateJadwalMutation();
  const [deleteJadwal, deleteJadwalResult] = useDeleteJadwalMutation();

  useEffect(() => {
    listJadwal();
  }, []);

  const jadwalDataOld = jadwalResult?.data?.data?.data || [];
  const jadwalData = jadwalDataOld.filter((item) => !item.parent_id);

  const handleCreateJadwal = (e, callback) => {
    createJadwal(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.data?.message);
      }
      callback();
      message.success(data?.message);
      return listJadwal();
    });
  };

  const handleUpdateJadwal = (e, callback) => {
    updateJadwal(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.message);
      }
      callback();
      message.success(data?.message);
      return listJadwal();
    });
  };

  const handleDeleteJadwal = (record) => {
    deleteJadwal({ uuid: record.uuid }).then(({ data, error }) => {
      if (data) {
        message.success('Data Berhasil Dihapus');
        return listJadwal();
      }

      message.error('Data Gagal Dihapus');
    });
  };

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Jadwal Pengecekan"
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={15}>
          <Col xs={24}>
            <div>
              <Cards
                title="List Jadwal"
                isbutton={
                  <ModalForm
                    onSubmit={(e, callback) => handleCreateJadwal(e, callback)}
                    loading={createJadwalResult.isLoading}
                    barang={barangData?.data?.data || []}
                  />
                }
              >
                <Table
                  pagination={false}
                  dataSource={jadwalData}
                  loading={jadwalResult.isLoading}
                  columns={[
                    ...dataTableColumn,
                    {
                      title: 'Actions',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      render: (text, record, index) => {
                        return (
                          <div className='flex justify-center items-center'>

                            <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ModalForm
                                update
                                key={index}
                                initialValues={{ ...record, _method: 'PUT' }}
                                onSubmit={(e, callback) => handleUpdateJadwal(e, callback)}
                                loading={updateJadwalResult.isLoading}
                                barang={barangData?.data?.data || []}
                              />
                            </div>

                            <div className='flex bg-red-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ConfirmModal handleClick={() => handleDeleteJadwal(record)}>
                                <div className="flex items-center justify-center">
                                  <DeleteOutlined style={{ fontSize:15, color: "#fff" }}/>
                                  <span className="ml-1 text-xs text-white">Delete</span>
                                </div>
                              </ConfirmModal>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </Cards>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default JadwalPage;
