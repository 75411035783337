/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Timeline, Carousel, Modal, Button, Row, Col, Form, Input, Select, Upload, message } from 'antd';
import { RightOutlined } from "@ant-design/icons"
import moment from 'moment';
import { LeftOutlined, LoadingOutlined, PlusOutlined  } from "@ant-design/icons"
import { Cards } from '../../../../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../../../../components/page-headers/page-headers';
import { useGetBarangDetailQuery } from '../../../../../../redux/service/masterService';
import { formatNumber } from '../../../../../../utility/utility';
import { DataService } from '../../../../../../config/dataService/dataService';
import { useForm } from 'antd/lib/form/Form';

const PageRoutes = [
    {
        path: '/admin/barang',
        breadcrumbName: 'Barang',
    },
    {
        path: '#',
        breadcrumbName: 'Detail Barang',
    },
];

function BarangUpdate() {
    const navigate = useNavigate()
    const location = useLocation()
    const { barang_id } = useParams();
    const [form] = useForm()
    const [imageLoading, setImageLoading]         = useState(false);
    const [fileListMultiple, setFileListMultiple] = useState([]);
    const listBarang = useGetBarangDetailQuery(barang_id);
    const barangDataOld = listBarang?.data?.data || {};
    const [fullImages, setFullImages] = useState("") 
    const [loadingHistoryUpdate, setLodaingHistoryUpdate] = useState(false)
    const [listHistoryUpdate, setListHistoryUpdate] = useState([])
    const [listImages, setListImages] = useState([])

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0]
        
        if(listBarang.data !== undefined) {
            getHistoryUpdate(listBarang.data.data.id)
        }

        if(fullImages !== "") {
            body.style.overflow = "hidden"

        } else {
            body.style.overflow = "auto"
        }

        if(barangDataOld?.foto_barang) {
            setListImages(barangDataOld?.foto_barang)
        }

        const images = barangDataOld?.foto_barang?.map((item) => {
        return {
            uid: item.id,
            status: 'done',
            url: item.foto_barang,
        };
        });

        setFileListMultiple(images);
        form.setFieldsValue(barangDataOld);

    }, [listBarang, fullImages, location])

    const handleChangeMultiple = ({ fileList: newFileList }) => {
        setFileListMultiple(newFileList);
    };

    const handleRemoveMultiple = async (file) => {
        console.log(file);
        try {
            const deletePhoto = await DataService.delete(`barang/delete-foto/${file.uid}`)
            if(deletePhoto.status === 200) {

            return message.success('Foto berhasil di hapus !');
            }
        
        } catch (error) {
            return message.error("Gagal menghapus foto !");
        }
    };

    const uploadButton = (
        <div>
            {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    function capitalizeFirstChar(inputString) {
        if (!inputString) {
            return "";
        }

        // Jika string hanya satu karakter, kembalikan string tanpa perubahan
        if (inputString.length === 1) {
            return inputString.toUpperCase();
        }

        // Capitalize karakter pertama
        let capitalizedString = inputString.charAt(0).toUpperCase() + inputString.slice(1);
        return capitalizedString;
    }

    const getHistoryUpdate = async (id) => {
        setLodaingHistoryUpdate(true)

        try {
        const history = await DataService.get(`/barang/history-update/${id}`)
        if(history.status === 200) {
            let dataUpdate = []

            if(history.data.data.length > 0) {
            for(const i in history.data.data) {
                dataUpdate.push({
                "children" : history.data.data[i].keterangan+" oleh "+ history.data.data[i].user_updated.name + " pada " + moment(history.data.data[i].created_at).format("HH:mm DD/MM/YYYY"),
                "color": parseInt(i) === (history.data.data.length-1) ? 'red' : 'green',
                })
            }
            }

            setListHistoryUpdate(dataUpdate)
        }
        } catch (error) {
        console.log("Filed load history ", error.message);
        }

        setLodaingHistoryUpdate(false)
    }

    const handleUpdateBarang = async (values) => {
        const formData = new FormData();
        fileListMultiple?.forEach((file) => {
            if (file.originFileObj) {
                formData.append('images[]', file.originFileObj);
            }
        });

        formData.append('kondisi', values.kondisi);
        try {
            const updateBarang = await DataService.post(`/barang/${barang_id}?is_check_barang=true`, formData, {
                'Content-Type': 'multipart/form-data'
            })
            
            message.success(updateBarang.data.message);
            return navigate(-1)

        } catch (error) {
            return message.error(error?.data?.message !== undefined ? error?.data?.message : "Error update barang");
        }
    }

    return (
        <>
        <PageHeader
            routes={PageRoutes}
            title={barangDataOld?.nama_barang}
            className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
        />
        <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
            <Cards title="Informasi Barang">
                <Row>

                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Nama Barang</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {barangDataOld?.nama_barang}
                        </span>
                        </div>
                    </div>
                    </Col>

                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Kode Resmi</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {barangDataOld?.kode_barang_resmi === null ? "-" : barangDataOld?.kode_barang_resmi}
                        </span>
                        </div>
                    </div>
                    </Col>

                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Kode Barang</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {barangDataOld?.kode_barang}
                        </span>
                        </div>
                    </div>
                    </Col>

                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Kategori Barang</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {barangDataOld?.nama_kategori}
                        </span>
                        </div>
                    </div>
                    </Col>

                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Kondisi</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {capitalizeFirstChar(barangDataOld?.kondisi)}
                        </span>
                        </div>
                    </div>
                    </Col>

                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Nama Lokasi</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {barangDataOld?.lokasi?.nama_lokasi}
                        </span>
                        </div>
                    </div>
                    </Col>

                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Tahun Pembelian</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {barangDataOld?.tahun_pembelian}
                        </span>
                        </div>
                    </div>
                    </Col>

                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Nilai Perolehan</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {formatNumber(barangDataOld?.nilai_perolehan)}
                        </span>
                        </div>
                    </div>
                    </Col>
                    <Col xs={24} sm={12} className='p-1'>
                    <div className='flex w-full flex-col my-2'>
                        <div className='flex w-full'>
                        <span className='font-medium text-gray-400 text-xs'>Karyawan</span>
                        </div>
                        <div className='flex w-full border-b border-b-gray-200 mt-2 pb-2'>
                        <span className='text-md'>
                            {(barangDataOld.karyawan !== undefined && barangDataOld.karyawan !== null) ? barangDataOld.karyawan.nama : "-"}
                        </span>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Cards>

            <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={(value) => handleUpdateBarang(value)}
                autoComplete="off"
            >
                <Cards title="Perbarui Barang" className={"mt-4"}>
                    <Row gutter={15}>
                        <Col xs={12}>
                                <Form.Item
                                    label="Kondisi"
                                    name="kondisi"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input Kondisi!',
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        placeholder="Pilih Kondisi"
                                        size="medium"
                                        name="kondisi"
                                        className="h-[37px]"
                                        options={[
                                            {
                                                value : "rusak ringan",
                                                label : "Rusak Ringan"
                                            },
                                            {
                                                value : "rusak berat",
                                                label : "Rusak Berat"
                                            },
                                            {
                                                value : "baik",
                                                label : "Baik"
                                            }
                                        ]}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Kondisi!',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={15}>
                        <Col xs={24}>
                            <Form.Item
                                label="Foto Aset (multiple)"
                                name="images"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Images!',
                                    },
                                ]}
                            >
                                <Upload
                                    name="images"
                                    className="avatar-uploader"
                                    multiple={true}
                                    beforeUpload={() => false}
                                    listType="picture-card"
                                    fileList={fileListMultiple}
                                    onChange={handleChangeMultiple}
                                    onRemove={handleRemoveMultiple}
                                >
                                    {fileListMultiple?.length >= 8 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                            <Button
                                onClick={() => form.submit()}
                                type="primary"
                                className="bg-blue-600 float-right"
                            >
                                <span className="ml-2">Simpan</span>
                            </Button>
                        </Col>
                    </Row>
                </Cards>
            </Form>
        </main>
        </>
    );
}

export default BarangUpdate;
