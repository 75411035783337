import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

export const masterService = createApi({
  reducerPath: 'masterService',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', `Bearer ${getItem('access_token')}`);
      return headers;
    },
  }),

  tagTypes: ['kategoriBarang'],
  endpoints: (builder) => ({
    // kategori
    getKategoriBarangList: builder.mutation({
      query: (body) => ({
        url: `/master/kategori/barang/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['kategoriBarang'],
    }),
    getKategoriBarang: builder.query({
      query: () => '/master/kategori/barang',
      providesTags: ['kategoriBarang'],
    }),
    createKategoriBarang: builder.mutation({
      query: (body) => ({
        url: `/master/kategori/barang`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kategoriBarang'],
    }),
    updateKategoriBarang: builder.mutation({
      query: (body) => ({
        url: `/master/kategori/barang/${body.uuid}`,
        method: 'PUT',
        body: { _method: 'PUT', ...body },
      }),
      invalidatesTags: ['kategoriBarang'],
    }),
    deleteKategoriBarang: builder.mutation({
      query: (body) => ({
        url: `/master/kategori/barang/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['kategoriBarang'],
    }),

    // metode penyusutan
    getMetodePenyusutanList: builder.mutation({
      query: (body) => ({
        url: `/master/metode-penyusutan/barang/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['metodePenyusutan'],
    }),
    getMetodePenyusutan: builder.query({
      query: () => '/master/metode-penyusutan/barang',
      providesTags: ['metodePenyusutan'],
    }),
    createMetodePenyusutan: builder.mutation({
      query: (body) => ({
        url: `/master/metode-penyusutan/barang`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['metodePenyusutan'],
    }),
    updateMetodePenyusutan: builder.mutation({
      query: (body) => ({
        url: `/master/metode-penyusutan/barang/${body.uuid}`,
        method: 'PUT',
        body: { _method: 'PUT', ...body },
      }),
      invalidatesTags: ['metodePenyusutan'],
    }),
    deleteMetodePenyusutan: builder.mutation({
      query: (body) => ({
        url: `/master/metode-penyusutan/barang/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['metodePenyusutan'],
    }),

    // lokasi
    getLokasiList: builder.mutation({
      query: (body) => ({
        url: `/lokasi/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['lokasi'],
    }),
    getLokasi: builder.query({
      query: () => '/lokasi',
      providesTags: ['lokasi'],
    }),
    createLokasi: builder.mutation({
      query: (body) => ({
        url: `/lokasi`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['lokasi'],
    }),
    updateLokasi: builder.mutation({
      query: (body) => ({
        url: `/lokasi/${body.uuid}`,
        method: 'PUT',
        body: { _method: 'PUT', ...body },
      }),
      invalidatesTags: ['lokasi'],
    }),
    deleteLokasi: builder.mutation({
      query: (body) => ({
        url: `/lokasi/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['lokasi'],
    }),

    // barang
    getBarangList: builder.mutation({
      query: (body) => ({
        url: `/barang/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['barang'],
    }),
    getBarang: builder.query({
      query: () => '/barang',
      providesTags: ['barang'],
    }),
    getBarangDetail: builder.query({
      query: (barang_id) => '/barang/' + barang_id,
      providesTags: ['barang'],
    }),
    createBarang: builder.mutation({
      query: (formData) => ({
        url: `/barang`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['barang'],
    }),
    updateBarang: builder.mutation({
      query: ({ formData, uuid }) => ({
        url: `/barang/${uuid}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['barang'],
    }),
    deleteBarang: builder.mutation({
      query: (body) => ({
        url: `/barang/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['barang'],
    }),
    importDataBarang: builder.mutation({
      query: (formData) => ({
        url: `/barang/import`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['barang'],
    }),

    // jadwal
    getJadwalList: builder.mutation({
      query: (body) => ({
        url: `/jadwal/list`,
        method: 'POST',
        body,
      }),
      providesTags: ['jadwal'],
    }),
    getJadwal: builder.query({
      query: () => '/jadwal',
      providesTags: ['jadwal'],
    }),
    getJadwalDetail: builder.query({
      query: (jadwal_id) => '/jadwal/' + jadwal_id,
      providesTags: ['jadwal'],
    }),
    createJadwal: builder.mutation({
      query: (formData) => ({
        url: `/jadwal`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['jadwal'],
    }),
    updateJadwal: builder.mutation({
      query: ({ formData, uuid }) => ({
        url: `/jadwal/${uuid}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['jadwal'],
    }),
    deleteJadwal: builder.mutation({
      query: (body) => ({
        url: `/jadwal/${body.uuid}`,
        method: 'DELETE',
        body: { _method: 'DELETE', ...body },
      }),
      invalidatesTags: ['jadwal'],
    }),
  }),
});

export const {
  useGetKategoriBarangQuery,
  useGetKategoriBarangListMutation,
  useCreateKategoriBarangMutation,
  useUpdateKategoriBarangMutation,
  useDeleteKategoriBarangMutation,

  // metode penyusutan
  useGetMetodePenyusutanQuery,
  useGetMetodePenyusutanListMutation,
  useCreateMetodePenyusutanMutation,
  useUpdateMetodePenyusutanMutation,
  useDeleteMetodePenyusutanMutation,

  // lokasi
  useGetLokasiQuery,
  useGetLokasiListMutation,
  useCreateLokasiMutation,
  useUpdateLokasiMutation,
  useDeleteLokasiMutation,

  // barang
  useGetBarangQuery,
  useGetBarangDetailQuery,
  useGetBarangListMutation,
  useCreateBarangMutation,
  useUpdateBarangMutation,
  useDeleteBarangMutation,

  // jadwal
  useGetJadwalQuery,
  useGetJadwalDetailQuery,
  useGetJadwalListMutation,
  useCreateJadwalMutation,
  useUpdateJadwalMutation,
  useDeleteJadwalMutation,
} = masterService;
