/* eslint-disable react/jsx-no-bind */
import { InboxOutlined, DeleteOutlined, CopyOutlined, EyeOutlined } from "@ant-design/icons"
import { Col, Pagination, Row, Table, message, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Swal from 'sweetalert2';
import ConfirmModal from '../../../../components/atoms/ConfirmModal';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../../components/page-headers/page-headers';
import {
  useCreateLokasiMutation,
  useDeleteLokasiMutation,
  useGetLokasiListMutation,
  useUpdateLokasiMutation,
} from '../../../../redux/service/masterService';
import ModalForm from './components/ModalForm';
import { useNavigate } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import { toPng } from 'html-to-image';

const PageRoutes = [
  {
    path: 'index',
    breadcrumbName: 'Master',
  },
  {
    path: 'master/lokasi',
    breadcrumbName: 'Lokasi',
  },
];
function LokasiPage() {
  const elementRef = useRef(null)
  const navigate = useNavigate()

  const [listLokasi, lokasiResult]          = useGetLokasiListMutation();
  const [updateLokasi, updateLokasiResult]  = useUpdateLokasiMutation();
  const [createLokasi, createLokasiResult]  = useCreateLokasiMutation();
  const [deleteLokasi, deleteLokasiResult]  = useDeleteLokasiMutation();
  const [showQrcodeData, setShowQrcodeData] = useState({
    status : false,
    qrcode : "",
  })

  const dataTableColumn = [
    {
      title: 'No.',
      dataIndex: 'nomor',
      key: 'nomor',
    },
    {
      title: 'ID Lokasi',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Kode Lokasi',
      dataIndex: 'kode_lokasi',
      key: 'kode_lokasi',
    },
    {
      title: 'QR Kode Lokasi',
      dataIndex: 'kode_lokasi',
      key: 'kode_lokasi',
      render : (_, record) => (
        <div className="cursor-pointer select-none" onClick={() => showQrcode(record.kode_lokasi)}>
          <QRCode value={record.kode_lokasi} size={50}/>
        </div>
      )
    },
    {
      title: 'Nama Lokasi',
      dataIndex: 'nama_lokasi',
      key: 'nama_lokasi',
    },
  ];

  useEffect(() => {
    listLokasi();
  }, []);

  const lokasiDataOld = lokasiResult?.data?.data?.data || [];
  const dataLokasiPagination = lokasiResult?.data?.data;
  const lokasiData = lokasiDataOld
    .filter((item) => !item.parent_id)
    .map((item, index) => {
      const cur_page = dataLokasiPagination?.current_page - 1;
      const per_page = dataLokasiPagination?.per_page;
      const no = index + 1;
      const page_no = cur_page * per_page + no;
      return {
        ...item,
        nomor: page_no,
      };
    });

  const handleCreateLokasi = (e, callback) => {
    createLokasi(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.message !== undefined ? error?.message : error.data.message);
      }
      callback();
      message.success(data?.message);
      return listLokasi();
    });
  };

  const handleUpdateLokasi = (e, callback) => {
    updateLokasi(e).then(({ error, data }) => {
      if (error) {
        return message.error(error?.message !== undefined ? error?.message : error.data.message);
      }
      callback();
      message.success(data?.message);
      return listLokasi();
    });
  };

  const handleDeleteLokasi = (record) => {
    deleteLokasi({ uuid: record.uuid }).then(({ data, error }) => {
      if (data) {
        message.success('Data Berhasil Dihapus');
        return listLokasi();
      }

      message.error('Data Gagal Dihapus');
    });
  };

  const copyID = () => {
    Swal.fire({
        title: 'Sukses!',
        text: 'ID Lokasi berhasil di salin !',
        icon: 'success',
        showConfirmButton : false,
        timer: 1500
    })
  }

  const showQrcode = async (qrcode) => {
    setShowQrcodeData({
        status : true,
        qrcode : qrcode
    })
  }

  const downloadQR = (name) => {
    toPng(elementRef.current, { cacheBust: false }).then((dataUrl) => {

        const link = document.createElement("a");
        link.download = `${name}.png`;
        link.href = dataUrl;
        link.click();

    }).catch((err) => {
        console.log(err);
    });
  }

  const handleQrcode = () => {
    setShowQrcodeData({
        status : false,
        qrcode : "",
        encrypt_qrcode : ""
    })
  }

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Lokasi"
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={15}>
          <Col xs={24}>
            <div>
              <Cards
                title="List Lokasi"
                isbutton={
                  <ModalForm
                    onSubmit={(e, callback) => handleCreateLokasi(e, callback)}
                    loading={createLokasiResult.isLoading}
                    parents={lokasiData}
                  />
                }
              >
                <Table
                  pagination={false}
                  dataSource={lokasiData}
                  loading={lokasiResult.isLoading}
                  key={lokasiData.length}
                  expandable={{ defaultExpandAllRows: true }}
                  columns={[
                    ...dataTableColumn,
                    {
                      title: 'Actions',
                      dataIndex: 'action',
                      key: 'action',
                      align: 'center',
                      render: (text, record, index) => {
                        return (
                          <div className='flex items-center justify-center'>
                            <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <p onClick={() => navigate('detail/' + record.uuid)}>
                                <div className="flex items-center justify-center">
                                  <EyeOutlined style={{ fontSize:15, color: "#fff"}}/>
                                </div>
                              </p>
                            </div>

                            <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ModalForm
                                update
                                key={index}
                                initialValues={{ ...record, _method: 'PUT' }}
                                onSubmit={(e, callback) => handleUpdateLokasi(e, callback)}
                                loading={updateLokasiResult.isLoading}
                                parents={lokasiData}
                              />
                            </div>

                            <div className='flex bg-red-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <ConfirmModal handleClick={() => handleDeleteLokasi(record)}>
                                <div className="flex items-center justify-center">
                                  <DeleteOutlined style={{ fontSize:15, color: "#fff" }}/>
                                  <span className="ml-1 text-xs text-white">Delete</span>
                                </div>
                              </ConfirmModal>
                            </div>

                            <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <p onClick={() => navigate(`../../barang?lokasi=${record.id}&nama=${record.nama_lokasi}`)}>
                                <div className="flex items-center justify-center">
                                  <InboxOutlined style={{ fontSize:15, color: "#fff"}}/>
                                </div>
                              </p>
                            </div>

                            <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                              <CopyToClipboard text={record.id} onCopy={() => copyID()}>
                                <div className="flex items-center justify-center">
                                  <CopyOutlined style={{ fontSize:15, color: "#fff" }}/>
                                </div>
                              </CopyToClipboard>
                            </div>

                          </div>
                        );
                      },
                    },
                  ]}
                />
                <Pagination
                  defaultCurrent={1}
                  current={dataLokasiPagination?.current_page}
                  total={dataLokasiPagination?.total}
                  className="mt-4 mx-auto"
                  onChange={(e) => listLokasi({ page: e })}
                />
              </Cards>
            </div>
          </Col>
        </Row>
      </main>

      {/* qrcode */}
      <Modal title="QRCODE" open={showQrcodeData.status} onOk={handleQrcode} onCancel={handleQrcode} cancelText="Tutup">
          <div className="flex flex-col">
              <div className="flex w-full items-center justify-center py-3 bg-white">
                  <div className="bg-white flex justify-center items-center w-[220px] h-[220px]" ref={elementRef}>
                      <QRCode value={showQrcodeData.qrcode} size={200}/>
                  </div>
              </div>
              <div className="flex mt-5 justify-center mb-3 flex-col items-center">
                  <div className="flex">
                      <span className='text-xs text-center'><b>Kode Lokasi :</b> {showQrcodeData.qrcode}</span>
                  </div>
              </div>
              <div className="flex justify-center items-center cursor-pointer select-none" onClick={() => downloadQR(`${showQrcodeData.qrcode}`)}>
                  <span className="font-medium text-blue-500 uppercase">Download QRCODE</span>
              </div>
          </div>
      </Modal>
    </>
  );
}

export default LokasiPage;
