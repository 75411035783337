import { PlusOutlined, FileExcelOutlined, ImportOutlined, FolderAddOutlined, FilterOutlined, FileWordOutlined, ClearOutlined, EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Pagination, Row, Table, message, Modal, Form, Upload, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_ENDPOINT } from '../../../config/dataService/dataService';

import ConfirmModal from '../../../components/atoms/ConfirmModal';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import {
  useCreateBarangMutation,
  useDeleteBarangMutation,
  useGetBarangListMutation,
  useUpdateBarangMutation,
  useGetKategoriBarangQuery,
  useGetLokasiQuery
} from '../../../redux/service/masterService';
import { dataTableColumn } from './config';
import { useNavigate } from 'react-router-dom';
import UilHdd from '@iconscout/react-unicons/icons/uil-hdd';
import { getItem } from '../../../utility/localStorageControl';
import { DataService } from '../../../config/dataService/dataService';
import axios from 'axios';

const { Dragger } = Upload

const PageRoutes = [
  {
    path: 'index',
    breadcrumbName: 'Barang',
  },
];

function BarangPage() {
  const searchParams = new URLSearchParams(document.location.search)
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const user = getItem('user');
  
  const [fileList, setFileList] = useState([]);
  const [listKaryawan, setListKaryawan]   = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [tableLoading, setTableLoading]   = useState(false);
  const [printLoading, setPrintLoading]   = useState(false);
  const [showModalsImportFile, setModalsImportFile] = useState(false)
  const [pageSize, setPageSize]   = useState(10);

  const [listBarang, barangResult] = useGetBarangListMutation();
  const [deleteBarang, deleteBarangResult] = useDeleteBarangMutation();
  const { data: kategoryData, isLoading: kategoriLoading } = useGetKategoriBarangQuery();
  const { data: lokasiData, isLoading: lokasiLoading } = useGetLokasiQuery();

  // filter
  const [filterTahunPerolehan, setTahunPerolehan] = useState(null)
  const [filterLokasi, setLokasi]                 = useState(null)
  const [filterKategori, setKategori]             = useState(null)
  const [filterKondisiBarang, setKondisiBarang]   = useState(null)
  const [filterKaryawan, setFilterKaryawan]       = useState(null)
  const [filterPencarian, setPencarian]           = useState(null)
  const [barangData, setBarangData]               = useState([])
  const [pagesBarangData, setpagesBarangData]     = useState({
    current_page : 0,
    total_page   : 0
  })

  const kategoryDatas = kategoryData?.data || [];
  const lokasiDatas   = lokasiData?.data || [];

  useEffect(() => {
    if(!searchParams.has("lokasi") && !searchParams.has("kategori") && !searchParams.has("kondisi")) {
      listDataBarang({ pages :1 })
      getDataKaryawan()
      
    } else {
      const lokasiParams = searchParams.get("lokasi")
      if(lokasiParams) {
        setLokasi(lokasiParams)
        form.setFieldValue("lokasi", lokasiParams)
    
        listDataBarang({ filterLokasiParams : lokasiParams })
      }
  
      const kategoriParams = searchParams.get("kategori")
      if(kategoriParams) {
        setKategori(kategoriParams)
        form.setFieldValue("kategori", kategoriParams)

        listDataBarang({ filterKategori : kategoriParams })
      }
  
      const kondisiBarangParams = searchParams.get("kondisi")
      if(kondisiBarangParams) {
        setKondisiBarang(kondisiBarangParams)
        form.setFieldValue("kondisi", kondisiBarangParams)

        listDataBarang({ filterKondisi : kondisiBarangParams.toLowerCase() })
      }
    }
  }, []);

  const handleDeleteBarang = (record) => {
    deleteBarang({ uuid: record.uuid }).then(({ data, error }) => {
      if (data) {
        message.success('Data Berhasil Dihapus');
        return proccessFilterData();
      }

      message.error('Data Gagal Dihapus');
    });
  };

  const listDataBarang = async ({pages=1, limit=10, filterTahun=null, filterKondisi=null, filterLokasiParams=null, filterKategori=null, filterPencarian=null, karyawan=null}) => {
    setTableLoading(true)
    try {
      const filterData = {
        pages : pages,
        perpage : limit
      }

      if(karyawan !== null) {
        filterData['karyawan_id'] = filterKaryawan
      }

      if(filterTahun !== null) {
        filterData['tahun_perolehan'] = filterTahun
      }

      if(filterKondisi !== null) {
        filterData['kondisi'] = filterKondisi
      }

      if(filterLokasiParams !== null) {
        filterData['lokasi_id'] = filterLokasiParams
      }

      if(filterKategori !== null) {
        filterData['kategori_barang_id'] = filterKategori
      }

      if(filterPencarian !== null) {
        filterData['search'] = filterPencarian
      }

      const dataBarang = await DataService.post("/barang/list", filterData)
      if(dataBarang.status === 200) {

        setpagesBarangData(dataBarang.data.data)

        const dataList = dataBarang.data.data.data.filter((item) => !item.parent_id)
        .map((item, index) => {
          const cur_page = dataBarang.data.data.current_page - 1;
          const per_page = dataBarang.data.data.per_page;
          const no = index + 1;
          const page_no = cur_page * per_page + no;
          return {
            ...item,
            nomor: page_no,
          };
        });

        setBarangData(dataList)
      }

    } catch (error) {
      message.error(error.message)
    }

    setTableLoading(false)
  }

  const handleChangeFile = (file) => {
    setFileList(file.fileList);
  };

  const proccessImportData = async () => {
      setUploadLoading(true)

      try {

        if(fileList.length > 0) {

          const dataForm = new FormData()
          dataForm.append('document_barang', fileList[0].originFileObj);

          const importData = await DataService.postFile("/barang/import", dataForm)
          if(importData.status === 200) {
              message.success(importData.data.message)
              setUploadLoading(false)

              setModalsImportFile(false)
              setFileList([])

              return listDataBarang({ pages :1 })
          }

        } else {

          setUploadLoading(false)
          return message.warning("Pastikan sudh memilih file yang di perlukan !")
        }

      } catch (error) {

          setUploadLoading(false)
          return message.error(error.message)
      }
  }

  const proccessExportData = async () => {
    setExportLoading(true)

    try {

      const exportData = await axios({
        url          : API_ENDPOINT+"/barang/export",
        method       : "GET",
        responseType : "blob",
        headers      : {
          Authorization: `Bearer ${getItem('access_token')}`
        }
      })

      if(exportData.status === 200) {

        const url  = window.URL.createObjectURL(new Blob([exportData.data]));
        const a    = document.createElement('a');

        a.href     = url;
        a.download = 'data_barang.xlsx';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);

        setUploadLoading(false)
        setModalsImportFile(false)

        setExportLoading(false)
        return message.success("Export data barang berhasil !")
      }

    } catch (error) {

      setExportLoading(false)
      return message.error(error.message)
    }
  }

  const proccessFilterData = async (pages=1, pageSize=10) => {
    setPageSize(pageSize)
    return listDataBarang({ 
      pages              : pages,
      limit              : pageSize,
      filterTahun        : filterTahunPerolehan, 
      filterKategori     : filterKategori, 
      filterKondisi      : filterKondisiBarang,
      filterPencarian    : filterPencarian,
      filterLokasiParams : filterLokasi,
      karyawan           : filterKaryawan
    })
  }

  const proccessPrintData = async () => {
    setPrintLoading(true)

    try {

      const filterData = {}

      if(filterTahunPerolehan !== null) {
        filterData['tahun_perolehan'] = filterTahunPerolehan
      }

      if(filterKondisiBarang !== null) {
        filterData['kondisi'] = filterKondisiBarang
      }

      if(filterLokasi !== null) {
        filterData['lokasi_id'] = filterLokasi
      }

      if(filterKategori !== null) {
        filterData['kategori_barang_id'] = filterKategori
      }

      if(filterPencarian !== null) {
        filterData['search'] = filterPencarian
      }
      const printData = await axios({
        url          : API_ENDPOINT+"/barang/print",
        method       : "POST",
        responseType : "blob",
        data         : filterData,
        headers      : {
          Authorization: `Bearer ${getItem('access_token')}`
        }
      })

      if(printData.status === 200) {

        const url  = window.URL.createObjectURL(new Blob([printData.data]));
        const a    = document.createElement('a');

        a.href     = url;
        a.download = 'data_barang.pdf';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);

        setUploadLoading(false)
        setModalsImportFile(false)

        setExportLoading(false)
        message.success("Print data barang berhasil !")
      }

    } catch (error) {
      message.error(error.message)
    }

    setPrintLoading(false)
  }

  const clearFilter = () => {
    setKategori(null)
    setTahunPerolehan(null)
    setPencarian(null)
    setKondisiBarang(null)
    setLokasi(null)

    form.setFieldValue("tahun_perolehan", null)
    form.setFieldValue("lokasi", null)
    form.setFieldValue("kategori", null)
    form.setFieldValue("kondisi", null)
    form.setFieldValue("pencarian", null)

    listDataBarang({ pages :1 })
  }

  const getDataKaryawan = async () => {
    try {
      const karyawanData = await DataService.get("/karyawan")
      if(karyawanData.status === 200) {
        let converData = []
        const result   = karyawanData.data.data.data
        for(let i=0; i<result.length; i++) {
          converData.push({
            label : result[i].nama,
            value : result[i].id
          })
        }

        setListKaryawan(converData)
      }

    } catch (error) {
      console.log("err => ", error.message);
    }
  }

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title={`Barang ${searchParams.has("nama") ? " di "+searchParams.get("nama") : "" }`}
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />

      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={15}>
          <Col xs={24} className='mb-5'>
            <Cards title="Filter data">
              <Form
                form={form}
                name="basic"
                layout="vertical"
                autoComplete="off"
              >
                <Row gutter={15}>
                  <Col lg={8} sm={12} xs={24}>
                    <Form.Item
                      name="tahun_perolehan"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input type="number" value={filterTahunPerolehan} onChange={(e) => setTahunPerolehan(e.target.value)} placeholder='Masukan tahun perolehan' size="medium"/>
                    </Form.Item>

                    <Form.Item
                      name="lokasi"
                      rules={[
                        {
                          required: false
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Pilih Lokasi"
                        size="medium"
                        name="lokasi"
                        rules={[
                          {
                            required: false
                          },
                        ]}
                        onChange={(e) => setLokasi(e)}
                      >
                        {lokasiDatas.map((item) => (
                          <Select.Option key={item.id} value={`${item.id}`}>
                            {item.nama_lokasi}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={12} xs={24}>
                    <Form.Item
                      name="kategori"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Pilih Kategori"
                        size="medium"
                        name="kategori"
                        rules={[
                          {
                            required: true,
                            message: 'Please input Kondisi!',
                          },
                        ]}
                        onChange={(e) => setKategori(e)}
                      >
                        {kategoryDatas.map((item) => (
                          <Select.Option key={item.id} value={`${item.id}`}>
                            {item.nama_kategori}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="kondisi"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Pilih Kondisi Barang"
                        size="medium"
                        name="kondisi"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        initialvalues={filterKondisiBarang}
                        onChange={(e) => setKondisiBarang(e)}
                      >
                        <Select.Option value="rusak ringan">
                          Rusak ringan
                        </Select.Option>
                        <Select.Option value="rusak berat">
                          Rusak berat
                        </Select.Option>
                        <Select.Option value="baik">
                          Baik
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  <Col lg={8} sm={24} xs={24}>
                    <Row>
                      <Col xs={12}>
                        <Form.Item
                          className="mr-2"
                          name="karyawan"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            placeholder="Pilih karyawan"
                            size="medium"
                            name="karyawan"
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                            options={listKaryawan}
                            onChange={(e) => setFilterKaryawan(e)}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={12}>
                        <Form.Item
                          className="ml-2"
                          name="pencarian"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input type="text" value={filterPencarian} onChange={(e) => setPencarian(e.target.value)} placeholder='Cari barang' size="medium"/>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={24}>
                        <div className='flex'>
                          <Button type="primary" onClick={() => proccessFilterData()} className='bg-blue-600 w-full flex justify-center items-center'>
                            {
                              exportLoading === false &&
                              <FilterOutlined color="#fff" />
                            }

                            <span className="ml-2">Filter</span>
                          </Button>
                          <Button type="primary" onClick={() => clearFilter()} className='bg-blue-600 mx-2 w-full flex justify-center items-center'>
                            {
                              exportLoading === false &&
                              <ClearOutlined color="#fff" />
                            }

                            <span className="ml-2">Reset</span>
                          </Button>

                          <Button onClick={() => proccessPrintData()} type="primary" loading={printLoading} className="bg-blue-600 w-full justify-center flex items-center">
                            {
                              printLoading === false &&
                              <FileWordOutlined color="#fff" />
                            }

                            <span className="ml-2">Print</span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Cards>
          </Col>
          

          <Col xs={24}>
            <div>
              <Cards
                title="List Barang"
                isbutton={
                  user?.role?.role_type != 'member' && (
                    <div className='flex w-full'>
                      <Button onClick={() => navigate('form')} type="primary" className="bg-blue-600 flex items-center m-1">
                        <PlusOutlined color="#fff" />
                        <span className="ml-2">Barang</span>
                      </Button>

                      <Button onClick={() => setModalsImportFile(true)} type="primary" className="bg-blue-600 flex items-center m-1">
                        <ImportOutlined color="#fff" />
                        <span className="ml-2">Import</span>
                      </Button>

                      <Button onClick={() => proccessExportData()} type="primary" loading={exportLoading} className="bg-blue-600 flex items-center m-1">
                        {
                          exportLoading === false &&
                          <FileExcelOutlined color="#fff" />
                        }

                        <span className="ml-2">Export</span>
                      </Button>
                    </div>
                  )
                }
              >
                <div className="overflow-x-auto max-w-[100%]">
                  <Table
                    pagination={false}
                    dataSource={barangData}
                    loading={tableLoading}
                    rowKey='id'
                    columns={[
                      ...dataTableColumn,
                      {
                        title: 'Actions',
                        dataIndex: 'action',
                        key: 'action',
                        align: 'center',
                        render: (text, record, index) => {
                          return (
                            <div className='flex items-center justify-center'>

                              <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                                <p onClick={() => navigate('detail/' + record.uuid)}>
                                  <div className="flex items-center justify-center">
                                    <EyeOutlined style={{ fontSize:15, color: "#fff"}}/>
                                  </div>
                                </p>
                              </div>

                              <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                                <p onClick={() => navigate('form/' + record.uuid)}>
                                  <div className="flex items-center justify-center">
                                      <EditOutlined style={{ fontSize:15, color: "#fff" }}/>
                                      <span className='text-xs text-white ml-1'>
                                        Edit
                                      </span>
                                  </div>
                                </p>
                              </div>

                              <div className='flex bg-red-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                                <ConfirmModal handleClick={() => handleDeleteBarang(record)}>
                                  <div className="flex items-center justify-center">
                                    <DeleteOutlined style={{ fontSize:15, color: "#fff" }}/>
                                    <span className="ml-1 text-xs text-white">Delete</span>
                                  </div>
                                </ConfirmModal>
                              </div>
                            </div>
                          );
                        },
                      },
                    ]}
                  />

                  <Pagination
                    showSizeChanger
                    // onShowSizeChange={onShowSizeChange}
                    pageSize={pageSize}
                    defaultCurrent={1}
                    current={pagesBarangData?.current_page}
                    total={pagesBarangData?.total}
                    className="mt-4 mx-auto"
                    onChange={(e, pageSize) => proccessFilterData(e, pageSize)}
                  />
                </div>

              </Cards>
            </div>
          </Col>
        </Row>
      </main>

      {/* modals import file excel */}
      <Modal
        title="Import Data Barang"
        open={showModalsImportFile}
        onCancel={() => setModalsImportFile(false)}
        footer={null}
      >
        <div className='border-t border-t-gray-100 mt-2 pt-5 pb-2'>
          <Dragger 
            name='file'
            maxCount={1}
            multiple={false}
            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' 
            onChange={handleChangeFile}
            fileList={fileList}
          >

            <div className='h-[70px] flex items-center justify-center flex-col'>
              <FolderAddOutlined style={{ fontSize: "40px", color:'gray' }}/>
              <p className="ant-upload-text mt-2 text-gray-400 text-sm">Klik atau seret file csv, xlsx ke sini</p>
            </div>

          </Dragger>

          <div className='flex items-center w-full justify-center mt-5'>
            <Button onClick={() => proccessImportData()} type="primary" loading={uploadLoading} className="bg-blue-600 flex items-center">
              {
                uploadLoading === false &&
                <FileExcelOutlined color="#fff" />
              }

              <span className="ml-2">Proses Import</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default BarangPage;
