const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Barang',
    dataIndex: 'nama_barang',
    key: 'nama_barang',
  },
  {
    title: 'Nama Penerima',
    dataIndex: 'nama_penerima',
    key: 'nama_penerima',
  },
  {
    title: 'Tanggal Hibah',
    dataIndex: 'tanggal_hibah',
    key: 'tanggal_hibah',
  },
];

export { dataTableColumn };
