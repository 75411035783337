import { Tag } from 'antd';

const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Pengguna',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Role',
    dataIndex: 'role_name',
    key: 'role_name',
    render: (text, record) => {
      return record?.role?.role_name || '-';
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => (text > 0 ? <Tag color="green">Aktif</Tag> : <Tag color="red">Tidak Aktif</Tag>),
  },
];

export { dataTableColumn };
