const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Nama Jadwal',
    dataIndex: 'judul',
    key: 'judul',
  },
  {
    title: 'Tanggal Pengecekan',
    dataIndex: 'tanggal',
    key: 'tanggal',
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    key: 'keterangan',
  },
];

export { dataTableColumn };
