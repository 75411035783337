import React, { useEffect } from 'react';
import { Modal } from '../modals/antd-modals';

const ConfirmModal = ({ children, title = 'Konfirmasi Hapus', body = 'Yakin Ingin Hapus Data?', handleClick }) => {
  const [visible, setVisible] = React.useState(false);

  const okPressed = () => {
    handleClick()
    setVisible(false)
  }

  return (
    <div>
      <div onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
        {children}
      </div>

      <Modal visible={visible} title={title} onCancel={() => setVisible(false)} onOk={() => okPressed()}>
        <p>{body}</p>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
