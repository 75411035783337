import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from '../../config/dataService/dataService';

export const authService = createApi({
  reducerPath: 'authService',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    // prepareHeaders: (headers, { getState }) => {
    //   headers.set("Authorization", `Bearer ${getItem("access_token")}`)
    //   return headers
    // },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: `/auth/login`,
        method: 'POST',
        body: body,
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: `/auth/register`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation } = authService;
