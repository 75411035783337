import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import themeReducer from './reducer/themeReducer';
import authReducer from './reducer/authReducer';
import sitesReducer from './reducer/sitesReducer';

import { authService } from './service/authService';
import { userService } from './service/userService';
import { sitesService } from './service/sitesService';
import { masterService } from './service/masterService';
import { pemindahanService } from './service/pemindahanService';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    sites: sitesReducer,

    // Add other reducers here if needed
    [authService.reducerPath]: authService.reducer,
    [userService.reducerPath]: userService.reducer,
    [sitesService.reducerPath]: sitesService.reducer,
    [masterService.reducerPath]: masterService.reducer,
    [pemindahanService.reducerPath]: pemindahanService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authService.middleware,
      userService.middleware,
      sitesService.middleware,
      pemindahanService.middleware,
      masterService.middleware,
    ),
});

setupListeners(store.dispatch);
