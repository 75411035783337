import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import DetailLokasi from '../../container/pages/master/lokasi/detail';

const NotFound = lazy(() => import('../../container/pages/404'));
const Maintenance = lazy(() => import('../../container/pages/Maintenance'));
const KategoriPage = lazy(() => import('../../container/pages/master/kategori'));
const MetodePenyusutan = lazy(() => import('../../container/pages/master/metode-penyusutan'));
const Lokasi = lazy(() => import('../../container/pages/master/lokasi'));

function MasterRoute() {
  return (
    <Routes>
      <Route path="kategori-barang" element={<KategoriPage />} />
      <Route path="metode-penyusutan" element={<MetodePenyusutan />} />
      <Route path="maintenance" element={<Maintenance />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default MasterRoute;
