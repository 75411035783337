import { PlusOutlined, DeleteOutlined, EditOutlined, InboxOutlined, EyeOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { Button, Col, Pagination, Row, Table, message, Modal, Form, Upload, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { DataService } from '../../../config/dataService/dataService';
import { useNavigate } from 'react-router-dom';
import { listBiro } from '../../../config/dataStatic';

const { Dragger } = Upload;
const { Search }  = Input

const PageRoutes = [
  {
    path: 'index',
    breadcrumbName: 'Karyawan',
  },
];

function KaryawanPage() {
  const navigate                                      = useNavigate()
  const [ form ]                                      = useForm()
  const [pageSize, setPageSize]                       = useState(10);
  const [filterPencarian, setPencarian]               = useState("")
  const [karyawanData, setkaryawanData]               = useState([])
  const [loadingRequest, setLoadingRequest]           = useState(true)
  const [imageEdit, setImageEdit]                     = useState("")
  const [showPreviewProfile, setShowPreviewProfile]   = useState({
    status : false,
    image  : "" 
  })
  const [showAlertDelete, setShowAlertDelete]         = useState({
    status : false,
    id     : ""
  })
  const [loadingDelete, setLoadingDelete]             = useState({
    status : false,
    id     : ""
  })
  const [loadingPostAndUpdate, setLoadingPostAndUpdate]           = useState(true)
  const [showModalsCreateAndUpdate, setShowModalsCreateAndUpdate] = useState({
    type   : "create",
    status : false,
    id     : ""
  })
  const [pageskaryawanData, setpageskaryawanData]     = useState({
    current_page : 1,
    total_page   : 0
  })

  const dataTableColumn = [
    {
      title: 'No.',
      dataIndex: 'nomor',
      key: 'nomor',
      render: (text, record, index) => (pageskaryawanData.current_page - 1) * pageSize + index + 1
    },
    {
      title: 'Foto',
      dataIndex: 'foto',
      key: 'foto',
      render : (_, recored) => (
        <img src={recored.foto} className="w-[50px] rounded-md cursor-pointer select-none" onClick={() => setShowPreviewProfile({ status:true, image:recored.foto })}/>
      )
    },
    {
      title: 'Nama',
      dataIndex: 'nama',
      key: 'nama',
    },
    {
      title: 'NIP',
      dataIndex: 'nip',
      key: 'nip',
    },
    {
      title: 'Divisi/Biro',
      dataIndex: 'instansi',
      key: 'instansi',
    },
    {
      title: 'Jabatan',
      dataIndex: 'jabatan',
      key: 'jabatan',
    }
  ];

  const listDataKaryawan = async (page=1, itemSize=10, search="") => {
    setLoadingRequest(true)
    try {
      const employe = await DataService.get(`/karyawan?page=${page}&perpage=${itemSize}&search=${search}`)
      if(employe.status === 200) {
        setkaryawanData(employe.data.data.data)
        setpageskaryawanData({
          current_page : employe.data.data.current_page,
          total_page   : employe.data.data.total
        })
      }

    } catch (error) {
      console.log("failed : ", error);

    } finally {
      setLoadingRequest(false)
    }
  }

  const updateAndCreateEmploye = async (value, type) => {
    setLoadingPostAndUpdate(true)
    try {
        const postData = new FormData()
        if(value.foto !== undefined) {
          postData.append("foto", value.foto.file)
        }

        postData.append("nama", value.nama)
        postData.append("nip", value.nip)
        postData.append("instansi", value.instansi)
        postData.append("jabatan", value.jabatan)

        if(type === "create") {
          await DataService.post("/karyawan", postData, {
            'Content-Type': 'multipart/form-data'
          })

          message.success("Berhasil di tambahkan !")

        } else {
          await DataService.post(`/karyawan/${showModalsCreateAndUpdate.id}`, postData, {
            'Content-Type': 'multipart/form-data'
          })

          message.success("Berhasil di perbarui !")

        }

        setShowModalsCreateAndUpdate({
          status : false,
          id     : "",
          type   : "create"
        })

        listDataKaryawan()
        setImageEdit("")
        form.resetFields()

    } catch (error) {
      if(error !== undefined) {
        message.error(error?.message !== undefined ? error?.message : error.data.message);
      }

    } finally {
      setLoadingPostAndUpdate(false)
    }
  }

  const handleDelete = async (id) => {
    setLoadingDelete({
      status : true,
      id     : id
    })

    try {
      await DataService.delete(`/karyawan/${id}`)
      await listDataKaryawan()
      
      setShowAlertDelete({
        status : false,
        id     : ""
      })
      return message.success("Berhasil di hapus !")

    } catch (error) {
      if(error !== undefined) {
        setShowAlertDelete({
          status : false,
          id     : ""
        })
        return message.error(error?.message !== undefined ? error?.message : error.data.message);
      }

    } finally {
      setLoadingDelete({
        status : false,
        id     : ""
      })
    }
  }

  const showModalsEdit = (record) => {
    if(record.foto !== "") {
      setImageEdit(record.foto)
    }

    form.setFieldValue("nama", record.nama)
    form.setFieldValue("nip", record.nip)
    form.setFieldValue("instansi", record.instansi)
    form.setFieldValue("jabatan", record.jabatan)
    setShowModalsCreateAndUpdate({ status:true, type:"edit", id: record.id })
  }

  const proccessFilterData = async (pages=1, pageSize=10) => {
    listDataKaryawan(pages, pageSize, filterPencarian)
    setPageSize(pageSize)
    setpageskaryawanData({
      current_page : pages
    })
  }

  useEffect(() => {
    listDataKaryawan()
  }, []);

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        className="flex  justify-between items-center px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />

      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={15}>
          <Col xs={24}>
            <div>
              <Cards
                title="List Karyawan"
                isbutton={
                    <div className='flex w-full'>
                      <Button onClick={() => setShowModalsCreateAndUpdate({ type:"create", status:true })} type="primary" className="bg-blue-600 flex items-center m-1">
                        <PlusOutlined color="#fff" />
                        <span className="ml-2">Karyawan</span>
                      </Button>
                    </div>
                }
              >
                <div className="flex justify-end mb-3">
                  <Search
                    size="large"
                    placeholder="Cari ..."
                    value={filterPencarian}
                    allowClear
                    onChange={(e) => setPencarian(e.target.value)}
                    onSearch={(keywords) => {
                      listDataKaryawan(pageskaryawanData.current_page, pageSize, keywords)
                    }}
                    style={{
                      width: 250,
                    }}
                  />
                </div>

                <div className="overflow-x-auto max-w-[100%]">
                  <Table
                    pagination={false}
                    dataSource={karyawanData}
                    loading={loadingRequest}
                    rowKey='id'
                    columns={[
                      ...dataTableColumn,
                      {
                        title: 'Actions',
                        dataIndex: 'action',
                        key: 'action',
                        align: 'center',
                        render: (text, record, index) => {
                          return (
                            <div className='flex items-center justify-center'>
                              <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                                <p onClick={() => navigate('detail/' + record.id)}>
                                  <div className="flex items-center justify-center">
                                    <EyeOutlined style={{ fontSize:15, color: "#fff"}}/>
                                  </div>
                                </p>
                              </div>

                              <div className='flex bg-blue-500 px-3 py-2 rounded mx-1 cursor-pointer select-none'>
                                <p onClick={() => showModalsEdit(record)}>
                                  <div className="flex items-center justify-center">
                                      <EditOutlined style={{ fontSize:15, color: "#fff" }}/>
                                      <span className='text-xs text-white ml-1'>
                                        Edit
                                      </span>
                                  </div>
                                </p>
                              </div>

                              <Button className='flex bg-red-500 hover:border-red-500 px-3 py-2 rounded mx-1 cursor-pointer select-none' loading={loadingDelete.status} onClick={() => setShowAlertDelete({ status:true, id:record.id })}>
                                <div className="flex items-center justify-center">
                                  <DeleteOutlined style={{ fontSize:15, color: "#fff" }}/>
                                  <span className="ml-1 text-xs text-white">Delete</span>
                                </div>
                              </Button>
                            </div>
                          );
                        },
                      },
                    ]}
                  />

                  <Pagination
                    showSizeChanger
                    pageSize={pageSize}
                    defaultCurrent={1}
                    current={pageskaryawanData?.current_page}
                    total={pageskaryawanData?.total_page}
                    className="mt-4 mx-auto"
                    onChange={(e, pageSize) => proccessFilterData(e, pageSize)}
                  />
                </div>

              </Cards>
            </div>
          </Col>
        </Row>
      </main>

      {/* add and update */}
      <Modal 
        cancelText="Tutup"
        title={`${showModalsCreateAndUpdate.type === "create" ? "Tambah" : "Ubah"} Data Karyawan`} 
        open={showModalsCreateAndUpdate.status} 
        onOk={() => { form.submit() }} 
        onCancel={() => {
          setShowModalsCreateAndUpdate({ status:false, type : "create" })

          setImageEdit("")
          form.resetFields()
        }} 
      >
          <div className="flex flex-col mt-5">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              onFinish={(value) => updateAndCreateEmploye(value, showModalsCreateAndUpdate.type)}
              autoComplete="off"
            >
                {
                  (showModalsCreateAndUpdate.type !== "create" && imageEdit !== "") &&
                  <div className="flex justify-center flex-col mb-3">
                    <div className="flex">
                      <span>Foto sekarang</span>
                    </div>
                    <div className="flex mt-2 justify-center">
                      <img src={imageEdit} className='rounded-md' style={{ height:150 }}/>
                    </div>
                  </div>
                }
                
                <Form.Item
                  label={`${showModalsCreateAndUpdate.type !== "create" ? "Ubah" : "" } Foto`}
                  name="foto"
                  rules={[
                    {
                      required: showModalsCreateAndUpdate.type === "create" ? true : false,
                      message: 'Masukan foto !'
                    }
                  ]}
                >
                  <Dragger beforeUpload={() => false}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Klik atau tarik foto ke area ini</p>
                  </Dragger>
                </Form.Item>

                <Form.Item
                  label="Nama"
                  name="nama"
                  rules={[
                    {
                      required: true,
                      message: 'Masukan nama !',
                    },
                  ]}
                >
                  <Input size="small" placeholder='Masukan Nama' style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>

                <Form.Item
                  label="NIP"
                  name="nip"
                  rules={[
                    {
                      required: true,
                      message: 'Masukan nip !',
                    },
                  ]}
                >
                  <Input size="small" placeholder='Masukan nip' style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>

                <Form.Item
                  label="Devisi/Biro"
                  name="instansi"
                  rules={[
                    {
                      required: true,
                      message: 'Masukan devisi atau instansi !',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Pilih Devisi/Biro"
                    size="medium"
                    name="instansi"
                    className="h-[37px]"
                    options={listBiro}
                    rules={[
                      {
                        required: true,
                        message: 'Please input Devisi/Biro!',
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Jabatan"
                  name="jabatan"
                  rules={[
                    {
                      required: true,
                      message: 'Masukan jabatan !',
                    },
                  ]}
                >
                  <Input size="small" placeholder='Masukan jabatan' style={{ paddingTop: 6, paddingBottom: 6 }} />
                </Form.Item>
            </Form>
          </div>
      </Modal>

      {/* modals alert delete */}
      <Modal title={"Konfirmasi Hapus"} open={showAlertDelete.status} onOk={() => { handleDelete(showAlertDelete.id) }} onCancel={() => { setShowAlertDelete({ status:false, id:"" }) }} cancelText="Tutup">
          <div className="flex flex-col">
            <span>Apakah anda yakin ingin menghapus data karyawan ?</span>
          </div>
      </Modal>

      {/* modals preview image */}
      <Modal title={"Preview Profile"} open={showPreviewProfile.status} onOk={() => { setShowPreviewProfile({ status:false, image:"" }) }} onCancel={() => { setShowPreviewProfile({ status:false, image:"" }) }} cancelText="Tutup">
          <div className="flex flex-col">
            <img src={showPreviewProfile.image} className='w-[300px]'/>
          </div>
      </Modal>
    </>
  );
}

export default KaryawanPage;
