export const listBiro = [
    {
        label : "Biro Administrasi Pimpinan",
        value : "Biro Administrasi Pimpinan"
    },
    {
        label : "Biro BUMD",
        value : "Biro BUMD"
    },
    {
        label : "Biro Hukum Dan HAM",
        value : "Biro Hukum Dan HAM"
    },
    {
        label : "Biro Kersa",
        value : "Biro Kersa"
    },
    {
        label : "Biro Organisasi",
        value : "Biro Organisasi"
    },
    {
        label : "Biro Pemerintah Dan Otonomi Daerah",
        value : "Biro Pemerintah Dan Otonomi Daerah"
    },
    {
        label : "Biro Pengadaan Barang Dan Jasa",
        value : "Biro Pengadaan Barang Dan Jasa"
    },
    {
        label : "Biro Perekonomian",
        value : "Biro Perekonomian"
    },
    {
        label : "Biro Umum",
        value : "Biro Umum"
    }
]