import { Tag } from 'antd';
import { formatNumber } from '../../../utility/utility';

const dataTableColumn = [
  {
    title: 'No.',
    dataIndex: 'nomor',
    key: 'nomor',
  },
  {
    title: 'Nama Barang',
    dataIndex: 'nama_barang',
    key: 'nama_barang',
  },
  {
    title: 'Kode Barang',
    dataIndex: 'kode_barang',
    key: 'kode_barang',
  },
  {
    title: 'Nama Kategori',
    dataIndex: 'nama_kategori',
    key: 'nama_kategori',
  },
  {
    title: 'Tahun Perolehan',
    dataIndex: 'tahun_pembelian',
    key: 'tahun_pembelian',
  },
  {
    title: 'Nilai Perolehan',
    dataIndex: 'nilai_perolehan',
    key: 'nilai_perolehan',
    render: (text) => formatNumber(text),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render : (text) => (text > 0 ? <Tag color="green">Aktif</Tag> : <Tag color="red">Tidak Aktif</Tag>),
  },
];

export { dataTableColumn };
