import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import HibahBarangPage from '../../container/pages/pemindahan/hibah-barang';
import JualBarangPage from '../../container/pages/pemindahan/jual-barang';
import PemusnahanBarangPage from '../../container/pages/pemindahan/pemusnahan-barang';
import TukarBarangPage from '../../container/pages/pemindahan/tukar-barang';

const NotFound = lazy(() => import('../../container/pages/404'));
const Maintenance = lazy(() => import('../../container/pages/Maintenance'));

function PemindahanRoute() {
  return (
    <Routes>
      <Route path="jual" element={<JualBarangPage />} />
      <Route path="hibah" element={<HibahBarangPage />} />
      <Route path="pemusnahan" element={<PemusnahanBarangPage />} />
      <Route path="tukar" element={<TukarBarangPage />} />
      <Route path="maintenance" element={<Maintenance />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PemindahanRoute;
